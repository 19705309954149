// 画布初始化配置
export const InitGraphInfo = {
    autoResize: false,
    magnetThreshold: 3,//鼠标移动多少次后才触发连线，或者设置为 'onleave' 时表示鼠标移出元素时才触发连线，默认为 0
    panning: {//画布是否可以拖拽平移
        enabled: true,
        modifiers: 'alt',
    }, // 普通画布(未开启 scroller 模式)通过开启 panning 选项来支持拖拽平移。
    mousewheel: { // 鼠标滚轮缩放
        enabled: true,
        // 是否为全局事件，设置为 true 时滚轮事件绑定在 document 上，否则绑定在画布容器上。默认为 false。
        global: true,
        modifiers: ['ctrl']
    },
    grid: {
        size: 10, // 网格大小 10px
        visible: true, // 绘制网格，默认绘制 dot 类型网格
        args: {
            color: '#a0a0a0', // 网格线/点颜色
            thickness: 1,     // 网格线宽度/网格点大小
        }
    },
    highlighting: {
        // 连线过程中，节点可以被链接时被使用
        // nodeAvailable: {},
        // 拖动节点进行嵌入操作过程中，节点可以被嵌入时被使用
        embedding: {
            name: 'stroke',
            args: {
                attrs: {
                    fill: '#fff',
                    stroke: '#47C769',
                },
            },
        },
        // 连线过程中，链接桩可以被链接时被使用
        magnetAvailable: { // 高亮
            name: 'stroke',
            args: {
                attrs: {
                    fill: '#fff',
                    stroke: '#47C769',
                },
            },
        },
        // 连线过程中，自动吸附到链接桩时被使用
        magnetAdsorbed: {
            name: 'stroke',
            args: {
                attrs: {
                    fill: 'red',
                    stroke: 'bluesky',
                },
            },
        },
    },
}
export const GraphSizeOptions = [
    {
        value: '1920*1080',
        label: '1920*1080'
    },
    {
        value: '1060*520',
        label: '1060*520'
    },
    {
        value: '1440*900',
        label: '1440*900'
    },
    {
        value: '1366*768',
        label: '1366*768'
    },
    {
        value: '999',
        label: '自定义'
    }
]
export const GraphGridTypes = [
    {
        value: 'dot',
        label: 'dot'
    },
    {
        value: 'fixedDot',
        label: 'fixedDot'
    },
    {
        value: 'mesh',
        label: 'mesh'
    },
    {
        value: 'doubleMesh',
        label: 'doubleMesh'
    }
]
export const InitConnecting = {
    router: {
        name: 'orth',
        args: {
            padding: 1
        }
    },
    connector: {
        name: 'jumpover',
        args: {
            type: 'arc',
            radius: 1,
            size: 0
        }
    },
    anchor: 'center',
    connectionPoint: 'anchor',
    snap: true
}
export const GraphBgImageRepeatOptions = [
    {
        value: 'no-repeat',
        label: 'no repeat'
    },
    {
        value: 'repeat',
        label: 'repeat'
    },
    {
        value: 'repeat-x',
        label: 'repeat x'
    },
    {
        value: 'repeat-y',
        label: 'repeat y'
    },
    {
        value: 'round',
        label: 'round'
    },
    {
        value: 'space',
        label: 'space'
    },
    {
        value: 'flipX',
        label: 'flip x'
    },
    {
        value: 'flipY',
        label: 'flip y'
    },
    {
        value: 'flipXY',
        label: 'flip xy'
    },
    {
        value: 'watermark',
        label: 'watermark'
    }
]

export const GraphBgImagePositionOptions = [
    {
        value: 'center',
        label: 'center'
    },
    {
        value: 'left',
        label: 'left'
    },
    {
        value: 'right',
        label: 'right'
    },
    {
        value: 'top',
        label: 'top'
    },
    {
        value: 'bottom',
        label: 'bottom'
    }
]

export const GraphBgImageSizeOptions = [
    {
        value: 'auto auto',
        label: 'auto auto'
    },
    {
        value: 'cover',
        label: 'cover'
    },
    {
        value: 'contain',
        label: 'contain'
    },
    {
        value: '100% 100%',
        label: '100% 100%'
    }
]
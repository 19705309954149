<template>
  <el-dialog
    :model-value="dialogVisible"
    :title="title"
    width="800px"
    @opened="handleOpen"
    @close="handleClose"
    :close-on-click-modal="false"
    draggable
  >
    <!-- <el-card> -->
    <el-form label-width="90px" label-position="left" :model="content">
      <el-form-item label="动作：">
        <el-select v-model="content.operatType">
          <el-option key="1" label="打开外链" :value="1" />
          <el-option key="2" label="打开内链" :value="2" />
          <el-option key="0" label="数据源赋值" :value="0" />
          <el-option key="3" label="数据源赋动态值" :value="3" />
        </el-select>
      </el-form-item>
      <el-form-item label="链接：" v-if="content.operatType == 1">
        <el-input v-model="content.link" />
      </el-form-item>
      <el-form-item label="路由：" v-if="content.operatType == 2">
        <el-input v-model="content.link" />
      </el-form-item>
      <el-form-item label="类型：" v-if="content.operatType == 2">
        <el-select v-model="content.openType">
          <el-option key="0" label="页面切换" :value="0" />
          <el-option key="1" label="打开新页面" :value="1" />
          <el-option key="2" label="弹窗显示" :value="2" />
        </el-select>
      </el-form-item>
      <el-form-item
        label="标题："
        v-if="content.operatType == 2 && content.openType == 2"
      >
        <el-input v-model="content.title" />
      </el-form-item>
      <el-form-item
        label="宽度："
        v-if="content.operatType == 2 && content.openType == 2"
      >
        <el-input v-model="content.width" />
      </el-form-item>
      <el-form-item
        label="高度："
        v-if="content.operatType == 2 && content.openType == 2"
      >
        <el-input v-model="content.height" />
      </el-form-item>
      <el-form-item
        label="关闭反馈："
        v-if="content.operatType == 2 && content.openType == 2"
      >
        <el-input v-model="content.closeSendInfo" />
      </el-form-item>

      <el-form-item
        label="设备"
        prop="deviceID"
        v-if="scenesBaseInfo.Type != 1 && content.operatType == 3"
      >
        <el-select
          v-model="selectDevice"
          placeholder="请选择设备"
          style="width: 100%"
          @change="handleChangeDevice"
          value-key="id"
          filterable
        >
          <el-option
            v-for="item in DeviceList"
            :key="item.id"
            :label="item.name"
            :value="item"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="参数"
        prop="parameName"
        v-if="content.operatType == 3"
      >
        <el-select
          v-model="selectParame"
          placeholder="请选择参数"
          style="width: 100%"
          filterable
          value-key="name"
        >
          <el-option
            v-for="item in ParameList"
            :key="item.name"
            :label="item.name"
            :value="item"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <el-table
      :data="content.rules"
      border
      style="width: 100%"
      v-if="content.operatType == 0"
    >
      <el-table-column type="index" width="50"> </el-table-column>
      <el-table-column
        property="deviceName"
        label="设备"
        width="200"
        v-if="scenesBaseInfo.Type != 1"
      />
      <el-table-column property="parameName" label="参数" width="200" />
      <el-table-column
        property="value"
        label="设置值"
        v-if="content.operatType == 0"
      />
      <el-table-column prop="action" label="操作">
        <template #default="scope">
          <el-button
            size="small"
            type="primary"
            :icon="Edit"
            @click="handleDialogValue(scope.$index, scope.row)"
          ></el-button>
          <el-button
            size="small"
            type="danger"
            :icon="Delete"
            @click="delRule(scope.$index)"
          ></el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="addRow" v-if="content.operatType == 0">
      <el-button
        style="width: 30%; margin: 10px 0px"
        type="danger"
        @click="handleDialogValue()"
        :icon="Plus"
        >添加规则</el-button
      >
    </div>
    <!-- </el-card> -->
    <Dialog
      v-model="ruleDialogInfo.visible"
      :dialogTitle="ruleDialogInfo.title"
      :dialogRowValue="ruleDialogInfo.rowValue"
      :ruleIndex="ruleDialogInfo.ruleIndex"
      :scenesBaseInfo="scenesBaseInfo"
      v-if="ruleDialogInfo.visible"
      @changeRules="changeRules"
    ></Dialog>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">取消</el-button>
        <el-button type="primary" @click="handleConfirm">确认</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { Plus, Edit, Delete } from '@element-plus/icons-vue'
import { GetDeviceFillList } from '@/api/Device'
import {
  GetDeviceParameListByDeviceID,
  GetDeviceParameList
} from '@/api/DeviceParame'
import { ElMessage, ElMessageBox } from 'element-plus'
import { ref, reactive } from 'vue'
import { isNull } from '@/utitls/validate'
import Dialog from './actionRuleDialog.vue'
const props = defineProps({
  modeType: {
    type: Number,
    required: true
  },
  content: {
    type: Object
  },
  scenesBaseInfo: {
    type: Object,
    default: {
      sceneType: 0,
      deviceTypeID: '',
      deviceTypeName: ''
    }
  }
})
const scenesBaseInfo = ref({})
const formRef = ref(null)
var form = ref({ modeType: 0 })
const dialogVisible = ref(false)
const title = ref('交互设置')
var DeviceList = reactive([])
var ParameList = reactive([])
const selectDevice = ref({
  id: '',
  name: ''
})
const selectParame = ref({
  id: '',
  name: ''
})
const content = ref({
  operatType: 0,
  link: '',
  rules: []
})
const ruleDialogInfo = ref({
  visible: false,
  title: '添加规则',
  rowValue: {},
  ruleIndex: -1,
  operatType: 0
})
const emits = defineEmits(['update:modelValue', 'setData'])
const handleOpen = () => {
  scenesBaseInfo.value = props.scenesBaseInfo
  form.value.modeType = props.modeType
  InitList()
  switch (props.modeType) {
    case 0:
      title.value = '交互设置(按下)'
      break
    case 1:
      title.value = '交互设置(抬起)'
      break
    case 2:
      title.value = '交互设置(单击)'
      break
    case 3:
      title.value = '交互设置(双击)'
      break
    default:
      break
  }
  if (typeof props.content != 'undefined') {
    content.value = JSON.parse(JSON.stringify(props.content))
    if (content.value.operatType == 3) {
      selectDevice.value = {
        id: content.value.deviceID,
        name: content.value.deviceName
      }
      selectParame.value = {
        id: content.value.parameID,
        name: content.value.parameName
      }
    }
    if (scenesBaseInfo.value.Type == 1) GetParameList()
  } else {
    content.value = {
      operatType: 0,
      link: '',
      rules: []
    }
    if (scenesBaseInfo.value.Type == 1) GetParameList()
  }
}
const handleClose = () => {
  emits('update:modelValue', false)
}
const handleConfirm = () => {
  if (content.value.operatType == 3) {
    if (
      scenesBaseInfo.value.Type != 1 &&
      (selectDevice.value.id == '' || selectParame.value.id == '')
    ) {
      ElMessage({
        message: '设备或参数不能为空',
        type: 'error'
      })
      return
    } else if (scenesBaseInfo.value.Type == 1 && selectParame.value.id == '') {
      ElMessage({
        message: '设备参数不能为空',
        type: 'error'
      })
      return
    }
    content.value.deviceID = selectDevice.value.id
    content.value.deviceName = selectDevice.value.name
    content.value.parameID = selectParame.value.id
    content.value.parameName = selectParame.value.name
  }
  console.log(content.value)
  emits('setData', props.modeType, content.value)
  emits('update:modelValue', false)
}
const handleDialogValue = (index, row) => {
  if (isNull(row)) {
    ruleDialogInfo.value.ruleIndex = -1
    ruleDialogInfo.value.title = '添加规则'
    ruleDialogInfo.value.rowValue = {}
  } else {
    ruleDialogInfo.value.ruleIndex = index
    ruleDialogInfo.value.title = '编辑规则'
    ruleDialogInfo.value.rowValue = row
  }
  ruleDialogInfo.value.operatType = content.value.operatType
  ruleDialogInfo.value.visible = true
}

const delRule = (index) => {
  ElMessageBox.confirm('是否确认删除当前规则?', '提醒', {
    confirmButtonText: '确认',
    cancelButtonText: '取消',
    type: 'warning'
  })
    .then(async () => {
      try {
        content.value.rules.splice(index, 1)
        ElMessage({
          type: 'success',
          message: '删除成功!'
        })
      } catch (err) {
        ElMessage({
          type: 'error',
          message: '删除失败!'
        })
        console.log(err)
      }
    })
    .catch(() => {})
}
const changeRules = (index, data) => {
  if (!content.value.hasOwnProperty('rules')) {
    content.value.rules = []
  }

  if (index >= 0) {
    //修改
    content.value.rules.splice(index, 1, data)
  } else {
    //新增
    content.value.rules.push(data)
  }
}
const InitList = () => {
  if (scenesBaseInfo.value.Type != 1) {
    let parame = { key: '' }
    GetDeviceFillList(parame)
      .then((res) => {
        DeviceList.length = 0
        res.Data.forEach((item) => {
          DeviceList.push({
            id: item.ID,
            name: item.Name
          })
        })
      })
      .catch((err) => {
        console.log(err)
      })
  }
}
const GetParameList = () => {
  ParameList.length = 0
  if (scenesBaseInfo.value.Type == 1) {
    let parame = {
      deviceTypeID: scenesBaseInfo.value.DeviceType,
      readType: '读写'
    }
    GetDeviceParameList(parame)
      .then((res) => {
        res.Data.forEach((item) => {
          ParameList.push({
            id: item.ID,
            name: item.Name
          })
        })
      })
      .catch((err) => {
        console.log(err)
      })
  } else {
    let parame = { deviceID: selectDevice.value.id, readType: '读写' }
    GetDeviceParameListByDeviceID(parame)
      .then((res) => {
        res.Data.forEach((item) => {
          ParameList.push({
            id: item.ID,
            name: item.Name
          })
        })
      })
      .catch((err) => {
        console.log(err)
      })
  }
}
const handleChangeDevice = (val) => {
  form.value.parameID = ''
  form.value.parameName = ''
  selectParame.value = {
    id: '',
    name: ''
  }
  GetParameList()
}
</script>

<style lang="scss" scoped>
:deep(.el-table--fit) {
  margin-top: 10px;
}
.addRow {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ebeef5;
  border-top: none;
}
</style>

<template>
  <el-dialog
    :model-value="dialogVisible"
    :title="title"
    width="1200px"
    @opened="handleOpen"
    @close="handleClose"
    :close-on-click-modal="false"
    draggable
  >
    <el-card>
      <el-button type="primary" @click="handleDialogValue()" :icon="Plus"
        >添加规则</el-button
      >
      <el-table :data="content.rules" border style="width: 100%">
        <el-table-column type="index" width="50"> </el-table-column>
        <el-table-column
          property="deviceName"
          label="设备"
          width="200"
          v-if="scenesBaseInfo.Type != 1"
        />
        <el-table-column property="parameName" label="参数" width="200" />
        <el-table-column property="colorValue" label="颜色" />
        <el-table-column property="maxValue" label="<=" />
        <el-table-column property="minValue" label=">=" />
        <el-table-column prop="action" label="操作">
          <template #default="scope">
            <el-button
              size="small"
              type="primary"
              :icon="Edit"
              @click="handleDialogValue(scope.$index, scope.row)"
            ></el-button>
            <el-button
              size="small"
              type="danger"
              :icon="Delete"
              @click="delRule(scope.$index)"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <Dialog
      v-model="ruleDialogInfo.visible"
      :dialogTitle="ruleDialogInfo.title"
      :dialogRowValue="ruleDialogInfo.rowValue"
      :ruleIndex="ruleDialogInfo.ruleIndex"
      :scenesBaseInfo="scenesBaseInfo"
      v-if="ruleDialogInfo.visible"
      @changeRules="changeRules"
    ></Dialog>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">取消</el-button>
        <el-button type="primary" @click="handleConfirm">确认</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { Plus, Edit, Delete } from '@element-plus/icons-vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import { ref, reactive } from 'vue'
import { isNull } from '@/utitls/validate'
import Dialog from './changeColorRuleDialog.vue'
const props = defineProps({
  modeType: {
    type: Number,
    required: true
  },
  rules: {
    type: Object
  },
  scenesBaseInfo: {
    type: Object,
    default: {
      sceneType: 0,
      deviceTypeID: '',
      deviceTypeName: ''
    }
  }
})
const scenesBaseInfo = ref({})
const formRef = ref(null)
const dialogVisible = ref(false)
const title = ref('颜色变化')
const content = ref({
  rules: []
})
const ruleDialogInfo = ref({
  visible: false,
  title: '添加规则',
  rowValue: {},
  ruleIndex: -1
})
const emits = defineEmits(['update:modelValue', 'setData'])
const handleOpen = () => {
  scenesBaseInfo.value = props.scenesBaseInfo
  if (typeof props.rules != 'undefined') {
    content.value.rules = JSON.parse(JSON.stringify(props.rules))
  } else {
    content.value.rules = []
  }
}
const handleClose = () => {
  emits('update:modelValue', false)
}
const handleConfirm = () => {
  emits('setData', props.modeType, content.value.rules)
  emits('update:modelValue', false)
}
const handleDialogValue = (index, row) => {
  if (isNull(row)) {
    ruleDialogInfo.value.ruleIndex = -1
    ruleDialogInfo.value.title = '添加规则'
    ruleDialogInfo.value.rowValue = {}
  } else {
    ruleDialogInfo.value.ruleIndex = index
    ruleDialogInfo.value.title = '编辑规则'
    ruleDialogInfo.value.rowValue = JSON.parse(JSON.stringify(row)) //避免引用传递，做一次数据拷贝
  }
  ruleDialogInfo.value.visible = true
}
const delRule = (index) => {
  ElMessageBox.confirm('是否确认删除当前规则?', '提醒', {
    confirmButtonText: '确认',
    cancelButtonText: '取消',
    type: 'warning'
  })
    .then(async () => {
      try {
        content.value.rules.splice(index, 1)
        ElMessage({
          type: 'success',
          message: '删除成功!'
        })
      } catch (err) {
        ElMessage({
          type: 'error',
          message: '删除失败!'
        })
      }
    })
    .catch(() => {})
}
const changeRules = (index, data) => {
  if (index >= 0) {
    //修改
    content.value.rules.splice(index, 1, data)
  } else {
    //新增
    content.value.rules.push(data)
  }
  console.log(content.value.rules)
}
</script>

<style lang="scss" scoped>
:deep(.el-table--fit) {
  margin-top: 10px;
}
</style>

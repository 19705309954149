<template>
  <el-dialog
    :model-value="dialogVisible"
    title="双击选择背景图片"
    width="1000px"
    @close="handleClose"
    :close-on-click-modal="false"
    draggable
  >
    <el-card>
      <div v-for="(imgInfo, index) in imgInfoList" :key="index" class="avatar">
        <el-avatar
          shape="square"
          :size="100"
          fit="contain"
          :src="imgInfo.url"
          @dblclick="handleConfirm(imgInfo.url)"
        />
      </div>
    </el-card>
  </el-dialog>
</template>

<script setup>
import { ref } from 'vue'
import { Plus } from '@element-plus/icons-vue'
import { GetBgImgList } from '@/api/SCADABgImg'
const imgInfoList = ref([])
const initGetList = async () => {
  imgInfoList.value.length = 0
  GetBgImgList()
    .then((res) => {
      if (res) {
        res.Data.forEach((element) => {
          imgInfoList.value.push({
            name: element.ID,
            url: element.Content
          })
        })
      } else {
        imgInfoList.value.length = 0
      }
    })
    .catch((err) => {
      imgInfoList.value.length = 0
    })
}
initGetList()
const dialogVisible = ref(false)

const emits = defineEmits(['update:modelValue', 'changeBgImg'])
const handleClose = () => {
  emits('update:modelValue', false)
}
const handleConfirm = (imgContent) => {
  emits('changeBgImg', imgContent)
  handleClose()
}
</script>

<style lang="scss" scoped>
.avatar {
  display: inline-block;
  margin: 10px;
}
</style>

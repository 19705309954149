import $http from '@/utitls/request'
/**
 * 获取组态节点填充信息
 */
export const GetNodeFillList = (data) => {
    return $http.get('/api/Node/GetNodeFillList', { params: data })
}
/**
 * 分页获取组态节点信息
 */
export const GetNodePageList = (data) => {
    return $http.get('/api/Node/GetNodePageList', { params: data })
}
/**
 * 新增
 */
export const AddNode = (data) => {
    return $http.post('/api/Node/AddNode', data)
}
/**
 * 修改
 */
export const EditNode = (data) => {
    return $http.put('/api/Node/EditNode', data)
}
/**
 * 删除
 */
export const DeleteNode = (data) => {
    return $http.delete('/api/Node/DeleteNode', { params: data })
}
/**
 * 图片批量上传
 */
export const UploadeImages = (datas) => {
    return $http.post('/api/Node/UploadeImages', datas, { headers: { 'Content-Type': 'multipart/form-data' } })
}
/**
 * 获取所有节点信息
 */
export const GetAllNodes = () => {
    return $http.post('/api/Node/GetAllNodes')
}
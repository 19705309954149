// 组件初始化配置
export const InitStencilInfo = {
    title: '元件库',
    // search: true,
    // placeholder: '搜索',
    // notFoundText: '无匹配元件',
    collapsable: true,
    stencilGraphWidth: 200,
    stencilGraphHeight: 0,
}
//连接桩
export const InitPortsInfo = {
    groups: {
        top: {
            position: 'top',
            attrs: {
                circle: {
                    r: 4,
                    magnet: true,
                    stroke: '#5F95FF',
                    strokeWidth: 1,
                    fill: '#fff',
                    style: {
                        visibility: 'hidden'
                    }
                }
            }
        },
        right: {
            position: 'right',
            attrs: {
                circle: {
                    r: 4,
                    magnet: true,
                    stroke: '#5F95FF',
                    strokeWidth: 1,
                    fill: '#fff',
                    style: {
                        visibility: 'hidden'
                    }
                }
            }
        },
        bottom: {
            position: 'bottom',
            attrs: {
                circle: {
                    r: 4,
                    magnet: true,
                    stroke: '#5F95FF',
                    strokeWidth: 1,
                    fill: '#fff',
                    style: {
                        visibility: 'hidden'
                    }
                }
            }
        },
        left: {
            position: 'left',
            attrs: {
                circle: {
                    r: 4,
                    magnet: true,
                    stroke: '#5F95FF',
                    strokeWidth: 1,
                    fill: '#fff',
                    style: {
                        visibility: 'hidden'
                    }
                }
            }
        }
    },
    items: [
        {
            group: 'top'
        },
        {
            group: 'right'
        },
        {
            group: 'bottom'
        },
        {
            group: 'left'
        }
    ]
}


<template>
  <div class="content">
    <div class="header align-center">
      <div class="title flexCC">组态管理/组态设计</div>
      <div class="name">{{ scenceName }}</div>
      <div class="btn">
        <el-button
          type="info"
          @click="handleAlignment(0)"
          plain
          :disabled="!selectMoreNode"
          color="#626aef"
          ><i class="iconfont icon-zuoduiqi" /><span style="margin-left: 4px"
            >左侧</span
          ></el-button
        >
        <el-button
          type="info"
          @click="handleAlignment(1)"
          plain
          :disabled="!selectMoreNode"
          color="#626aef"
          ><i class="iconfont icon-juzhongduiqi" /><span
            style="margin-left: 4px"
            >居中</span
          ></el-button
        >
        <el-button
          type="info"
          @click="handleAlignment(2)"
          plain
          :disabled="!selectMoreNode"
          color="#626aef"
          ><i class="iconfont icon-youduiqi" /><span style="margin-left: 4px"
            >右侧</span
          ></el-button
        >
        <el-divider direction="vertical" />
        <el-button
          type="info"
          @click="handleAlignment(3)"
          plain
          :disabled="!selectMoreNode"
          color="#626aef"
          ><i class="iconfont icon-dingduanduiqi" /><span
            style="margin-left: 4px"
            >顶部</span
          ></el-button
        >
        <el-button
          type="info"
          @click="handleAlignment(4)"
          plain
          :disabled="!selectMoreNode"
          color="#626aef"
          ><i class="iconfont icon-juzhongduiqi" /><span
            style="margin-left: 4px"
            >中部</span
          ></el-button
        >
        <el-button
          type="info"
          @click="handleAlignment(5)"
          plain
          :disabled="!selectMoreNode"
          color="#626aef"
          ><i class="iconfont icon-diduanduiqi" /><span style="margin-left: 4px"
            >底部</span
          ></el-button
        >
        <el-divider direction="vertical" />
        <el-button type="primary" @click="ChangeShowCode"
          ><i
            :class="['iconfont', showCode ? 'icon-icon4' : 'icon-yuanma']"
          /><span style="margin-left: 4px">{{
            showCode ? '设计' : '源码'
          }}</span></el-button
        >
        <el-button type="success" @click="SaveInfo"
          ><i class="iconfont icon-baocuncunchu" /><span
            style="margin-left: 4px"
            >保存</span
          ></el-button
        >
        <el-button type="warning" @click="PreView"
          ><i class="iconfont icon-yulan" /><span style="margin-left: 4px"
            >预览</span
          ></el-button
        >
      </div>
    </div>
    <div class="main flex-row">
      <div class="nodes">
        <div ref="refStencil" class="stencil"></div>
      </div>
      <div class="container" style="overflow: auto">
        <div
          ref="refContainer"
          style="width: 100%; height: 100%"
          v-show="!showCode"
        ></div>
        <div v-show="showCode">
          <div>
            画布:<el-input
              :rows="2"
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 15 }"
              resize="none"
              v-model="graphJson"
            />
          </div>
          <div>
            内容:<el-input
              :rows="2"
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 20 }"
              resize="none"
              v-model="contentJson"
            />
          </div>
        </div>
      </div>
      <div class="config">
        <el-collapse v-model="activeInfo.activeType" accordion>
          <el-collapse-item name="graphSet">
            <template #title>
              <span class="PropTitle">画布设置</span>
            </template>
            <el-tabs
              v-model="activeInfo.activeTab"
              type="border-card"
              stretch
              class="tabs"
            >
              <el-tab-pane label="属性" name="prop">
                <el-collapse v-model="activeInfo.activeGraphProp">
                  <el-collapse-item title="缩放" name="scale">
                    <el-input-number
                      v-model="graphInfo.scaleRate"
                      :min="0.01"
                      :max="16"
                      :precision="2"
                      :step="0.01"
                      @change="handleSetGraphScale"
                    />
                  </el-collapse-item>
                  <el-collapse-item title="尺寸" name="size">
                    <el-select
                      v-model="graphInfo.graphSize"
                      placeholder="请选择"
                      @change="handleChangeGraphSize"
                    >
                      <el-option
                        v-for="item in GraphSizeOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                    <div
                      class="rowInfo flex-row"
                      v-if="graphInfo.graphSize == 999"
                    >
                      <span class="proName align-center">宽度:</span>
                      <div class="proValue" style="width: 30%">
                        <el-input
                          v-model="graphInfo.graphW"
                          @change="handleChangGraphWH"
                        >
                        </el-input>
                      </div>
                      <span
                        class="proName align-center"
                        style="padding-left: 5px"
                        >高度:</span
                      >
                      <div class="proValue" style="width: 30%">
                        <el-input
                          v-model="graphInfo.graphH"
                          @change="handleChangGraphWH"
                        >
                        </el-input>
                      </div>
                    </div>
                  </el-collapse-item>
                  <el-collapse-item title="背景" name="bg">
                    <div class="rowInfo flexCB">
                      <div class="justify-center">
                        <div class="align-center">背景色：</div>
                        <el-color-picker
                          v-model="graphInfo.bgColor"
                          show-alpha
                          @change="handleDrawGraphBg"
                        />
                      </div>
                      <div>
                        <el-checkbox
                          v-model="graphInfo.useBgImage"
                          label="使用图片"
                          size="large"
                          @change="handleChangeGraphUseBgImage"
                        />
                      </div>
                    </div>
                    <div class="rowInfo flexCB" v-if="graphInfo.useBgImage">
                      <el-upload
                        :auto-upload="false"
                        :on-change="handleChangeImg"
                        :show-file-list="false"
                        action=""
                        style="width: 48%"
                      >
                        <el-button>本地图片</el-button>
                      </el-upload>
                      <div class="empty" style="width: 4%"></div>
                      <div style="width: 48%">
                        <el-button @click="bgImgDialogInfo.visible = true"
                          >系统图片</el-button
                        >
                      </div>
                    </div>
                    <div class="rowInfo flexCB" v-if="graphInfo.useBgImage">
                      <span class="proName align-center">位置:</span>
                      <div class="proValue">
                        <el-select
                          v-model="graphInfo.bgPosition"
                          @change="handleDrawGraphBg"
                        >
                          <el-option
                            v-for="item in GraphBgImagePositionOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          />
                        </el-select>
                      </div>
                    </div>
                    <div class="rowInfo flexCB" v-if="graphInfo.useBgImage">
                      <span class="proName align-center">大小:</span>
                      <div class="proValue">
                        <el-select
                          v-model="graphInfo.bgSize"
                          @change="handleDrawGraphBg"
                        >
                          <el-option
                            v-for="item in GraphBgImageSizeOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          />
                        </el-select>
                      </div>
                    </div>
                    <div class="rowInfo flexCB" v-if="graphInfo.useBgImage">
                      <span class="proName align-center">重复:</span>
                      <div class="proValue">
                        <el-select
                          v-model="graphInfo.bgRepeat"
                          @change="handleDrawGraphBg"
                        >
                          <el-option
                            v-for="item in GraphBgImageRepeatOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          />
                        </el-select>
                      </div>
                    </div>
                    <div class="rowInfo flexCB" v-if="graphInfo.useBgImage">
                      <span class="proName align-center">透明:</span>
                      <div class="proValue">
                        <el-input-number
                          v-model="graphInfo.bgOpacity"
                          @change="handleDrawGraphBg"
                          :precision="2"
                          :step="0.1"
                          :max="1"
                          :min="0"
                        />
                      </div>
                    </div>
                  </el-collapse-item>
                  <el-collapse-item title="网格" name="grid">
                    <div class="rowInfo flexCB">
                      <el-checkbox
                        v-model="graphInfo.showGrid"
                        label="启用"
                        size="large"
                        @change="handleChangeShowGraphGrid"
                      />
                    </div>
                    <div class="rowInfo flexCB" v-if="graphInfo.showGrid">
                      <span class="proName align-center">颜色:</span>
                      <div class="proValue">
                        <el-color-picker
                          v-model="graphInfo.gridColor"
                          show-alpha
                          @change="handleDrawGraphGrid"
                          :disabled="!graphInfo.showGrid"
                        />
                      </div>
                    </div>
                    <div class="rowInfo flexCB" v-if="graphInfo.showGrid">
                      <span class="proName align-center">类型:</span>
                      <div class="proValue">
                        <el-select
                          v-model="graphInfo.gridType"
                          @change="handleDrawGraphGrid"
                        >
                          <el-option
                            v-for="item in GraphGridTypes"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          />
                        </el-select>
                      </div>
                    </div>
                    <div class="rowInfo flexCB" v-if="graphInfo.showGrid">
                      <span class="proName align-center">大小:</span>
                      <div class="proValue">
                        <el-input-number
                          v-model="graphInfo.gridSize"
                          :min="1"
                          :max="20"
                          @change="handleChangeGridSize"
                        />
                      </div>
                    </div>
                    <div class="rowInfo flexCB" v-if="graphInfo.showGrid">
                      <span class="proName align-center">粗细:</span>
                      <div class="proValue">
                        <el-input-number
                          v-model="graphInfo.gridThickness"
                          :min="1"
                          :max="10"
                          :precision="1"
                          :step="0.1"
                          @change="handleDrawGraphGrid"
                        />
                      </div>
                    </div>
                  </el-collapse-item>
                  <el-collapse-item title="其他" name="other">
                    <div class="rowInfo flexCB">
                      <el-checkbox
                        v-model="graphInfo.usePorts"
                        label="启用连接桩"
                        size="large"
                      />
                    </div>
                    <div class="rowInfo flexCB">
                      <el-checkbox
                        v-model="graphInfo.transformEnable"
                        label="启用节点变形"
                        size="large"
                        @change="handleTransformEnable"
                      />
                    </div>
                  </el-collapse-item>
                </el-collapse>
              </el-tab-pane>
              <el-tab-pane label="事件" name="event">
                <el-collapse v-model="activeInfo.activeGraphEvent">
                  <el-collapse-item title="动效" name="tip">
                    <div class="rowInfo flexC" style="margin-top: 0px">
                      <el-checkbox
                        v-model="graphInfo.hasTip"
                        label="提示"
                        size="large"
                        style="margin: 0px 10%"
                      />
                      <el-button
                        link
                        :icon="Edit"
                        :disabled="!graphInfo.hasTip"
                        @click="showTipDialog"
                      />
                      <el-button
                        link
                        :icon="Delete"
                        :disabled="!graphInfo.hasTip"
                        @click="clearRules(0)"
                      />
                    </div>
                    <div class="rowInfo flexC" style="margin-top: 0px">
                      <el-checkbox
                        v-model="graphInfo.hasGraphDialog"
                        label="弹窗"
                        size="large"
                        style="margin: 0px 10%"
                      />
                      <el-button
                        link
                        :icon="Edit"
                        :disabled="!graphInfo.hasGraphDialog"
                        @click="showGraphDialog"
                      />
                      <el-button
                        link
                        :icon="Delete"
                        :disabled="!graphInfo.hasGraphDialog"
                        @click="clearRules(1)"
                      />
                    </div>
                  </el-collapse-item>
                </el-collapse>
              </el-tab-pane>
            </el-tabs>
          </el-collapse-item>
          <el-collapse-item name="nodeSet">
            <template #title>
              <span class="PropTitle">节点设置</span>
            </template>
            <el-tabs
              v-model="activeInfo.activeTab"
              type="border-card"
              stretch
              class="tabs"
            >
              <el-tab-pane label="属性" name="prop">
                <el-collapse v-model="activeInfo.activeNodeProp">
                  <el-collapse-item title="位置和尺寸" name="size">
                    <div class="rowInfo2">
                      <el-input
                        v-model="nodeInfo.width"
                        @change="handleChangeNodeWidth"
                        style="width: 45%; margin-right: 5%"
                        title="节点宽度"
                        ><template #prefix>W:</template></el-input
                      ><el-input
                        v-model="nodeInfo.height"
                        @change="handleChangeNodeHeight"
                        style="width: 45%"
                        title="节点高度"
                        ><template #prefix>H:</template></el-input
                      >
                    </div>
                    <div class="rowInfo2">
                      <el-input
                        v-model="nodeInfo.x"
                        @change="handleChangeNodeX"
                        style="width: 45%; margin-right: 5%"
                        title="节点X坐标"
                        ><template #prefix>X:</template></el-input
                      ><el-input
                        v-model="nodeInfo.y"
                        @change="handleChangeNodeY"
                        style="width: 45%"
                        title="节点Y坐标"
                        ><template #prefix>Y:</template></el-input
                      >
                    </div>
                    <div class="rowInfo2">
                      <el-input
                        v-model="nodeInfo.angle"
                        @change="handleChangeNodeAngle"
                        style="width: 45%; margin-right: 5%"
                        title="节点旋转角度"
                        ><template #prefix>A:</template></el-input
                      >
                    </div>
                  </el-collapse-item>
                  <el-collapse-item title="外观" name="bg">
                    <div class="rowInfo2">
                      <span style="margin-right: 10px">背景色:</span>
                      <el-color-picker
                        v-model="nodeInfo.bgColor"
                        show-alpha
                        @change="handleChangeNodeBgColor"
                      />
                      <span style="margin: 0px 10px">边框色:</span>
                      <el-color-picker
                        v-model="nodeInfo.borderColor"
                        show-alpha
                        @change="handleChangeNodeBorderColor"
                      />
                    </div>
                    <div class="rowInfo flexCB">
                      <span class="proName align-center">边宽:</span>
                      <div class="proValue">
                        <el-input-number
                          v-model="nodeInfo.borderWidth"
                          @change="handleChangeNodeBorderWidth"
                        />
                      </div>
                    </div>
                    <div class="rowInfo flexCB">
                      <span class="proName align-center">层级:</span>
                      <div class="proValue">
                        <el-input-number
                          v-model="nodeInfo.zIndex"
                          @change="handleChangeNodeZIndex"
                        />
                      </div>
                    </div>
                    <div class="rowInfo flexCB">
                      <span class="proName align-center">字体:</span>
                      <div class="proValue">
                        <el-select
                          v-model="nodeInfo.fontFamily"
                          @change="handleChangeNodeFontFamily"
                        >
                          <el-option
                            v-for="item in FontFamilyOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          >
                            <span :style="'font-family:' + item.label">{{
                              item.label
                            }}</span>
                          </el-option>
                        </el-select>
                      </div>
                    </div>
                    <div class="rowInfo flexCB">
                      <span class="proName align-center">字号:</span>
                      <div class="proValue">
                        <el-input-number
                          v-model="nodeInfo.fontSize"
                          @change="handleChangeNodeFontSize"
                        />
                      </div>
                    </div>
                    <div class="rowInfo flexCB">
                      <span class="proName align-center">样式:</span>
                      <div class="proValue">
                        <el-checkbox-button
                          v-model="nodeInfo.fontWeight"
                          label="B"
                          size="large"
                          title="加粗"
                          @change="handleChangeNodeFontWeight"
                        />
                        <el-checkbox-button
                          v-model="nodeInfo.fontStyle"
                          label="I"
                          size="large"
                          title="斜体"
                          @change="handleChangeNodeFontStyle"
                        />
                        <el-checkbox-button
                          v-model="nodeInfo.textDecoration"
                          label="U"
                          size="large"
                          title="下划线"
                          @change="handleChangeNodeTextDecoration"
                        />
                      </div>
                    </div>
                    <div class="rowInfo flexCB">
                      <span class="proName align-center">字色:</span>
                      <div class="proValue">
                        <el-color-picker
                          v-model="nodeInfo.fontColor"
                          show-alpha
                          @change="handleChangeNodeFontColor"
                        />
                      </div>
                    </div>
                    <div class="rowInfo flexCB">
                      <span class="proName align-center">水平:</span>
                      <div class="proValue">
                        <el-radio-group
                          size="small"
                          v-model="nodeInfo.textAnchor"
                          @change="handleChangeNodeTextAnchor"
                        >
                          <el-radio-button label="end" title="右对齐">
                            <i class="iconfont icon-youduiqi" />
                          </el-radio-button>
                          <el-radio-button label="middle" title="居中对齐">
                            <i class="iconfont icon-juzhongduiqi" />
                          </el-radio-button>
                          <el-radio-button label="start" title="左对齐">
                            <i class="iconfont icon-zuoduiqi" />
                          </el-radio-button>
                        </el-radio-group>
                      </div>
                    </div>
                    <div class="rowInfo flexCB">
                      <span class="proName align-center">垂直:</span>
                      <div class="proValue">
                        <el-radio-group
                          size="small"
                          v-model="nodeInfo.textVerticalAnchor"
                          @change="handleChangeNodeTextVerticalAnchor"
                        >
                          <el-radio-button label="bottom" title="顶端对齐">
                            <i class="iconfont icon-dingduanduiqi" />
                          </el-radio-button>
                          <el-radio-button label="middle" title="居中对齐">
                            <i class="iconfont icon-juzhongduiqi" />
                          </el-radio-button>
                          <el-radio-button label="top" title="底端对齐">
                            <i class="iconfont icon-diduanduiqi" />
                          </el-radio-button>
                        </el-radio-group>
                      </div>
                    </div>
                  </el-collapse-item>
                </el-collapse>
              </el-tab-pane>
              <el-tab-pane label="事件" name="event">
                <el-collapse v-model="activeInfo.activeNodeEvent">
                  <el-collapse-item title="交互" name="response">
                    <div class="rowInfo flexC" style="margin-top: 0px">
                      <el-checkbox
                        v-model="noedEvent.hasMousedown"
                        label="按 下"
                        size="large"
                        style="margin: 0px 10%"
                        @change="handleChangeNodeHasMousedown"
                      />
                      <el-button
                        link
                        :icon="Edit"
                        :disabled="!noedEvent.hasMousedown"
                        @click="showActionDialog(0)"
                      />
                      <el-button
                        link
                        :icon="Delete"
                        :disabled="!noedEvent.hasMousedown"
                        @click="clearRules(2)"
                      />
                    </div>
                    <div class="rowInfo flexC" style="margin-top: 0px">
                      <el-checkbox
                        v-model="noedEvent.hasMouseup"
                        label="抬 起"
                        size="large"
                        style="margin: 0px 10%"
                        @change="handleChangeNodeHasMouseup"
                      />
                      <el-button
                        link
                        :icon="Edit"
                        :disabled="!noedEvent.hasMouseup"
                        @click="showActionDialog(1)"
                      />
                      <el-button
                        link
                        :icon="Delete"
                        :disabled="!noedEvent.hasMouseup"
                        @click="clearRules(3)"
                      />
                    </div>
                    <div class="rowInfo flexC" style="margin-top: 0px">
                      <el-checkbox
                        v-model="noedEvent.hasClick"
                        label="单 击"
                        size="large"
                        style="margin: 0px 10%"
                        @change="handleChangeNodeHasClick"
                      />
                      <el-button
                        link
                        :icon="Edit"
                        :disabled="!noedEvent.hasClick"
                        @click="showActionDialog(2)"
                      />
                      <el-button
                        link
                        :icon="Delete"
                        :disabled="!noedEvent.hasClick"
                        @click="clearRules(4)"
                      />
                    </div>
                    <div class="rowInfo flexC" style="margin-top: 0px">
                      <el-checkbox
                        v-model="noedEvent.hasDblClick"
                        label="双 击"
                        size="large"
                        style="margin: 0px 10%"
                        @change="handleChangeNodeHasDblClick"
                      />
                      <el-button
                        link
                        :icon="Edit"
                        :disabled="!noedEvent.hasDblClick"
                        @click="showActionDialog(3)"
                      />
                      <el-button
                        link
                        :icon="Delete"
                        :disabled="!noedEvent.hasDblClick"
                        @click="clearRules(5)"
                      />
                    </div>
                  </el-collapse-item>
                  <el-collapse-item title="动效" name="action">
                    <div class="rowInfo flexC" style="margin-top: 0px">
                      <el-checkbox
                        v-model="noedEvent.hasShowHide"
                        label="显/隐"
                        size="large"
                        style="margin: 0px 10%"
                        @change="handleChangeNodeHasShowHide"
                      />
                      <el-button
                        link
                        :icon="Edit"
                        :disabled="!noedEvent.hasShowHide"
                        @click="showResponseDialog(0)"
                      />
                      <el-button
                        link
                        :icon="Delete"
                        :disabled="!noedEvent.hasShowHide"
                        @click="clearRules(6)"
                      />
                    </div>
                    <div class="rowInfo flexC" style="margin-top: 0px">
                      <el-checkbox
                        v-model="noedEvent.hasRotate"
                        label="旋 转"
                        size="large"
                        style="margin: 0px 10%"
                        @change="handleChangeNodeHasRotate"
                      />
                      <el-button
                        link
                        :icon="Edit"
                        :disabled="!noedEvent.hasRotate"
                        @click="showResponseDialog(1)"
                      />
                      <el-button
                        link
                        :icon="Delete"
                        :disabled="!noedEvent.hasRotate"
                        @click="clearRules(7)"
                      />
                    </div>
                    <div class="rowInfo flexC" style="margin-top: 0px">
                      <el-checkbox
                        v-model="noedEvent.hasBind"
                        label="赋 值"
                        size="large"
                        style="margin: 0px 10%"
                        @change="handleChangeNodeHasBind"
                      />
                      <el-button
                        link
                        :icon="Edit"
                        :disabled="!noedEvent.hasBind"
                        @click="showBindDialog()"
                      />
                      <el-button
                        link
                        :icon="Delete"
                        :disabled="!noedEvent.hasBind"
                        @click="clearRules(8)"
                      />
                    </div>
                    <div class="rowInfo flexC" style="margin-top: 0px">
                      <el-checkbox
                        v-model="noedEvent.hasChangeColor"
                        label="变 色"
                        size="large"
                        style="margin: 0px 10%"
                        @change="handleChangeNodeHasChangeColor"
                      />
                      <el-button
                        link
                        :icon="Edit"
                        :disabled="!noedEvent.hasChangeColor"
                        @click="showChangeColorDialog(0)"
                      />
                      <el-button
                        link
                        :icon="Delete"
                        :disabled="!noedEvent.hasChangeColor"
                        @click="clearRules(9)"
                      />
                    </div>
                    <div class="rowInfo flexC" style="margin-top: 0px">
                      <el-checkbox
                        v-model="noedEvent.hasChangeWH"
                        label="变 形"
                        size="large"
                        style="margin: 0px 10%"
                        @change="handleChangeNodeHasChangeWH"
                      />
                      <el-button
                        link
                        :icon="Edit"
                        :disabled="!noedEvent.hasChangeWH"
                        @click="showChangeWHDialog()"
                      />
                      <el-button
                        link
                        :icon="Delete"
                        :disabled="!noedEvent.hasChangeWH"
                        @click="clearRules(10)"
                      />
                    </div>
                  </el-collapse-item>
                </el-collapse>
              </el-tab-pane>
            </el-tabs>
          </el-collapse-item>
          <el-collapse-item name="edgeSet">
            <template #title>
              <span class="PropTitle">连线设置</span>
            </template>
            <el-tabs
              v-model="activeInfo.activeTab"
              type="border-card"
              stretch
              class="tabs"
            >
              <el-tab-pane label="属性" name="prop">
                <el-collapse v-model="activeInfo.activeEdgeProp">
                  <el-collapse-item title="外观" name="bg">
                    <div class="rowInfo flexCB">
                      <span class="align-center" style="width: 40%"
                        >开始箭头:</span
                      >
                      <div class="proValue" style="width: 60%">
                        <el-select
                          v-model="edgeInfo.sourceMarker"
                          @change="ChangeSourceMarkerType"
                        >
                          <el-option
                            v-for="item in EdgeMarkerOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          >
                          </el-option>
                        </el-select>
                      </div>
                    </div>
                    <div class="rowInfo flexCB">
                      <span class="align-center" style="width: 40%"
                        >结束箭头:</span
                      >
                      <div class="proValue" style="width: 60%">
                        <el-select
                          v-model="edgeInfo.targetMarker"
                          @change="ChangeTargetMarkerType"
                        >
                          <el-option
                            v-for="item in EdgeMarkerOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          >
                          </el-option>
                        </el-select>
                      </div>
                    </div>
                    <div class="rowInfo flexCB">
                      <span class="align-center" style="width: 40%"
                        >虚线间隔:</span
                      >
                      <div class="proValue" style="width: 60%">
                        <el-input-number
                          v-model="edgeInfo.strokeDasharray"
                          :min="0"
                          @change="handleEdgeStrokeDasharrayChange"
                        />
                      </div>
                    </div>
                    <div class="rowInfo flexCB">
                      <span class="align-center" style="width: 40%"
                        >显示层级:</span
                      >
                      <div class="proValue" style="width: 60%">
                        <el-input-number
                          v-model="edgeInfo.zIndex"
                          @change="handleEdgeZIndexChange"
                        />
                      </div>
                    </div>
                    <div class="rowInfo flexCB">
                      <span class="align-center" style="width: 40%"
                        >线段粗细:</span
                      >
                      <div class="proValue" style="width: 60%">
                        <el-input-number
                          v-model="edgeInfo.width"
                          @change="handleEdgeWidthChange"
                        />
                      </div>
                    </div>
                    <div class="rowInfo flexCB">
                      <span class="align-center" style="width: 40%"
                        >填充颜色:</span
                      >
                      <div class="proValue" style="width: 60%">
                        <el-color-picker
                          v-model="edgeInfo.fillColor"
                          show-alpha
                          @change="handleChangeEdgeFillColor"
                        />
                      </div>
                    </div>
                  </el-collapse-item>
                  <el-collapse-item title="连接器" name="connector">
                    <div class="rowInfo flexCB">
                      <span class="align-center" style="width: 40%"
                        >跳线类型:</span
                      >
                      <div class="proValue" style="width: 60%">
                        <el-select
                          v-model="edgeInfo.connectorParames.type"
                          @change="ChangeEdgeConnector"
                        >
                          <el-option key="normal" label="无" value="normal">
                          </el-option>
                          <el-option key="arc" label="弧形" value="arc">
                          </el-option>
                          <el-option key="gap" label="缺口" value="gap">
                          </el-option>
                          <el-option key="cubic" label="立方体" value="cubic">
                          </el-option>
                        </el-select>
                      </div>
                    </div>
                    <div
                      class="rowInfo flexCB"
                      v-if="edgeInfo.connectorParames.type != 'normal'"
                    >
                      <span class="align-center" style="width: 40%"
                        >跳线大小:</span
                      >
                      <div class="proValue" style="width: 60%">
                        <el-input-number
                          v-model="edgeInfo.connectorParames.size"
                          :min="0"
                          @change="ChangeEdgeConnector"
                        />
                      </div>
                    </div>
                    <div
                      class="rowInfo flexCB"
                      v-if="edgeInfo.connectorParames.type != 'normal'"
                    >
                      <span class="align-center" style="width: 40%"
                        >倒角半径:</span
                      >
                      <div class="proValue" style="width: 60%">
                        <el-input-number
                          v-model="edgeInfo.connectorParames.radius"
                          :min="0"
                          @change="ChangeEdgeConnector"
                        />
                      </div>
                    </div>
                  </el-collapse-item>
                  <el-collapse-item title="路径点" name="route">
                    <div class="rowInfo flexCB">
                      <div style="width: 48%">
                        <el-button
                          @click="ChangeRouteVisible(true)"
                          type="success"
                          :disabled="edgeInfo.showRoutePoint"
                          plain
                          style="width: 100%"
                          >设置</el-button
                        >
                      </div>
                      <div class="empty" style="width: 4%"></div>
                      <div style="width: 48%">
                        <el-button
                          @click="ChangeRouteVisible(false)"
                          type="success"
                          plain
                          :disabled="!edgeInfo.showRoutePoint"
                          style="width: 100%"
                          >取消</el-button
                        >
                      </div>
                    </div>
                  </el-collapse-item>
                </el-collapse>
              </el-tab-pane>
              <el-tab-pane label="事件" name="event">
                <el-collapse v-model="activeInfo.activeEdgeEvent">
                  <el-collapse-item title="动效" name="action">
                    <div class="rowInfo flexC" style="margin-top: 0px">
                      <el-checkbox
                        v-model="edgeEvent.hasShowHide"
                        label="显/隐"
                        size="large"
                        style="margin: 0px 10%"
                        @change="handleChangeEdgeHasShowHide"
                      />
                      <el-button
                        link
                        :icon="Edit"
                        :disabled="!edgeEvent.hasShowHide"
                        @click="showResponseDialog(2)"
                      />
                      <el-button
                        link
                        :icon="Delete"
                        :disabled="!edgeEvent.hasShowHide"
                        @click="clearRules(11)"
                      />
                    </div>
                    <div class="rowInfo flexC" style="margin-top: 0px">
                      <el-checkbox
                        v-model="edgeEvent.hasFlow"
                        label="流 动"
                        size="large"
                        style="margin: 0px 10%"
                        @change="handleChangeEdgeHasFlow"
                      />
                      <el-button
                        link
                        :icon="Edit"
                        :disabled="!edgeEvent.hasFlow"
                        @click="showResponseDialog(3)"
                      />
                      <el-button
                        link
                        :icon="Delete"
                        :disabled="!edgeEvent.hasFlow"
                        @click="clearRules(12)"
                      />
                    </div>
                    <div class="rowInfo flexC" style="margin-top: 0px">
                      <el-checkbox
                        v-model="edgeEvent.hasChangeColor"
                        label="变 色"
                        size="large"
                        style="margin: 0px 10%"
                        @change="handleChangeEdgeHasChangeColor"
                      />
                      <el-button
                        link
                        :icon="Edit"
                        :disabled="!edgeEvent.hasChangeColor"
                        @click="showResponseDialog(1)"
                      />
                      <el-button
                        link
                        :icon="Delete"
                        :disabled="!edgeEvent.hasChangeColor"
                        @click="clearRules(13)"
                      />
                    </div>
                  </el-collapse-item>
                </el-collapse> </el-tab-pane
            ></el-tabs>
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>
    <ActionDialog
      v-model="actionDialogInfo.visible"
      :modeType="actionDialogInfo.modeType"
      :content="actionDialogInfo.content"
      @setData="setNodeData"
      v-if="actionDialogInfo.visible"
      :scenesBaseInfo="scenesBaseInfo"
    ></ActionDialog>
    <ResponseDialog
      v-model="responseDialogInfo.visible"
      :modeType="responseDialogInfo.modeType"
      :content="responseDialogInfo.content"
      @setData="setResponseData"
      v-if="responseDialogInfo.visible"
      :scenesBaseInfo="scenesBaseInfo"
    ></ResponseDialog>
    <BindDialog
      v-model="bindDialogInfo.visible"
      :bindInfo="bindDialogInfo.bindInfo"
      @changeBindInfo="setBindInfo"
      v-if="bindDialogInfo.visible"
      :scenesBaseInfo="scenesBaseInfo"
    ></BindDialog>
    <BgImageDialog
      v-model="bgImgDialogInfo.visible"
      @changeBgImg="setBgImg"
      v-if="bgImgDialogInfo.visible"
      :scenesBaseInfo="scenesBaseInfo"
    ></BgImageDialog>
    <ChangeColorDialog
      v-model="changeColorDialogInfo.visible"
      @setData="setChangeColorRules"
      v-if="changeColorDialogInfo.visible"
      :rules="changeColorDialogInfo.rules"
      :modeType="changeColorDialogInfo.modeType"
      :scenesBaseInfo="scenesBaseInfo"
    ></ChangeColorDialog>
    <ChangeWHDialog
      v-model="changeWHDialogInfo.visible"
      :content="changeWHDialogInfo.content"
      @setData="setChangeWHData"
      v-if="changeWHDialogInfo.visible"
      :scenesBaseInfo="scenesBaseInfo"
    ></ChangeWHDialog>
    <TipDialog
      v-model="tipDialogInfo.visible"
      :content="tipDialogInfo.content"
      @setRules="setTipRules"
      v-if="tipDialogInfo.visible"
      :scenesBaseInfo="scenesBaseInfo"
    ></TipDialog>
    <GraphDialog
      v-model="graphDialogInfo.visible"
      :content="graphDialogInfo.content"
      @setRules="setGraphDialogRules"
      v-if="graphDialogInfo.visible"
      :scenesBaseInfo="scenesBaseInfo"
    ></GraphDialog>
    <DeviceListDialog
      v-model="deviceListDialogInfo.visible"
      :deviceTypeID="scenesBaseInfo.DeviceType"
      @setDeviceID="SetDeviceID"
      v-if="deviceListDialogInfo.visible"
    ></DeviceListDialog>
  </div>
</template>

<script setup>
import { ref, onMounted, reactive, watch, onUnmounted } from 'vue'
import { Edit, Promotion, Monitor, View, Delete } from '@element-plus/icons-vue'
import { Graph, Shape } from '@antv/x6'
import { Transform } from '@antv/x6-plugin-transform'
import { Snapline } from '@antv/x6-plugin-snapline'
import { Clipboard } from '@antv/x6-plugin-clipboard'
import { Keyboard } from '@antv/x6-plugin-keyboard'
import { History } from '@antv/x6-plugin-history'
import { Selection } from '@antv/x6-plugin-selection'
import { Scroller } from '@antv/x6-plugin-scroller'
import { MiniMap } from '@antv/x6-plugin-minimap'
import { Stencil } from '@antv/x6-plugin-stencil'
import { getBase64 } from '@/utitls/imgToBase64'
import {
  InitGraphInfo,
  GraphSizeOptions,
  GraphGridTypes,
  InitConnecting,
  GraphBgImageRepeatOptions,
  GraphBgImagePositionOptions,
  GraphBgImageSizeOptions
} from './assets/InitGraphInfo'
import { FontFamilyOptions } from './assets/InitNodeInfo'
import { SetKeyBoard } from './assets/InitKeyboard'
import { EdgeMarkerOptions, EdgeConnectorOptions } from './assets/InitEdgeInfo'
import { InitStencilInfo, InitPortsInfo } from './assets/InitStencilInfo'
import { GetAllNodes } from '@/api/Node'
import { GetScenesInfo, EditScenesInfo } from '@/api/Scenes'
import { useRoute } from 'vue-router'
import router from '@/router'
import { ElMessage, ElMessageBox } from 'element-plus'
import { isNull } from '@/utitls/validate'
import ActionDialog from './components/actionDialog.vue'
import ResponseDialog from './components/responseDialog.vue'
import BindDialog from './components/bindDialog.vue'
import BgImageDialog from './components/bgImageDialog.vue'
import ChangeColorDialog from './components/changeColorDialog.vue'
import ChangeWHDialog from './components/changeWHDialog.vue'
import TipDialog from './components/tipDialog.vue'
import GraphDialog from './components/graphDialog.vue'
import DeviceListDialog from './components/deviceListDialog.vue'

const route = useRoute()
var regUrl = new RegExp(window.global_config.BASE_URL, 'g') //g,表示全部替换。
var reg = new RegExp('@@@', 'g') //g,表示全部替换。
const graphJson = ref('')
const contentJson = ref('')
const scenceName = ref('')
const showCode = ref(false)
const ChangeShowCode = () => {
  if (!showCode.value) {
    graphJson.value = JSON.stringify(graphInfo.value).replace(regUrl, '@@@')
    contentJson.value = JSON.stringify(graph.toJSON()).replace(regUrl, '@@@')
  } else {
    graphInfo.value = JSON.parse(
      graphJson.value.replace(reg, window.global_config.BASE_URL)
    )
    initGraph()
    graph.fromJSON(
      JSON.parse(contentJson.value.replace(reg, window.global_config.BASE_URL))
    )
    initGraphInfo()
  }
  showCode.value = !showCode.value
}

var graph = reactive({})
var stencil = reactive({})
const selectNode = ref({})
const selectEdge = ref({})
const transform = ref({})
var graphInfo = ref({
  graphSize: '1920*1080',
  graphW: 1920,
  graphH: 1080,
  bgColor: 'rgba(235, 233, 241, 0.8)',
  useBgImage: false,
  bgImage: undefined,
  bgRepeat: 'no-repeat',
  bgPosition: 'center',
  bgSize: 'auto auto',
  bgOpacity: 1,
  showGrid: true,
  gridType: 'dot',
  gridColor: '#a0a0a0',
  gridThickness: 1,
  gridSize: 10,
  scaleRate: 1, //缩放比例
  usePorts: false,
  transformEnable: true,
  hasTip: false,
  hasGraphDialog: false,
  tipRules: [],
  hasDialog: false,
  dialogRules: {}
})
const refStencil = ref(null)
const refContainer = ref(null)
var nodeInfo = reactive({
  width: 60,
  height: 60,
  x: 0,
  y: 0,
  angle: 0,
  bgColor: '#ffffff',
  borderColor: '#333333',
  borderWidth: 2,
  fontFamily: '微软雅黑',
  fontSize: 14,
  fontWeight: false, //加粗
  fontStyle: false, //斜体
  textDecoration: false, //下划线
  fontColor: '#333333',
  zIndex: 0,
  textAnchor: 'middle', //水平对齐
  textVerticalAnchor: 'middle' //垂直对齐
})
var edgeInfo = reactive({
  sourceMarker: '', //开始箭头
  targetMarker: '', //结束箭头
  strokeDasharray: 0,
  fillColor: '#1890ff',
  showRoutePoint: false,
  width: 2,
  zIndex: 0,
  connectorParames: {
    type: 'arc',
    size: 1,
    radius: 0
  }
})
var activeInfo = reactive({
  activeType: 'graphSet',
  activeTab: 'prop',
  activeGraphProp: ['scale', 'size', 'bg', 'grid', 'other'],
  activeGraphEvent: ['tip'],
  activeNodeProp: ['size', 'bg', 'font'],
  activeNodeEvent: ['response', 'action'],
  activeEdgeProp: ['bg', 'font', 'connector', 'route'],
  activeEdgeEvent: ['response', 'action']
})
var noedEvent = reactive({
  hasMousedown: false,
  hasMouseup: false,
  hasClick: false,
  hasDblClick: false,
  hasShowHide: false,
  hasRotate: false,
  hasBind: false,
  hasChangeColor: false,
  hasChangeWH: false
})
var edgeEvent = reactive({
  hasShowHide: false,
  hasFlow: false,
  hasChangeColor: false
})
var actionDialogInfo = reactive({
  visible: false,
  modeType: 0,
  content: {}
})
var tipDialogInfo = reactive({
  visible: false,
  content: {}
})
var graphDialogInfo = reactive({
  visible: false,
  content: {}
})
var responseDialogInfo = reactive({
  visible: false,
  modeType: 0,
  content: {}
})
var bindDialogInfo = reactive({
  visible: false,
  bindInfo: {}
})
var changeColorDialogInfo = reactive({
  visible: false,
  rules: [],
  modeType: 0
})
var bgImgDialogInfo = reactive({
  visible: false
})
var changeWHDialogInfo = reactive({
  visible: false,
  content: {}
})
var deviceListDialogInfo = reactive({
  visible: false
})
var scenesInfo = ref({ name: '', content: '' })
const scenesBaseInfo = ref({
  Type: 0,
  deviceTypeID: '',
  deviceTypeName: ''
})
const selectMoreNode = ref(false)
const initGraph = () => {
  //画布初始化
  if (JSON.stringify(graph) != '{}') {
    graph.dispose()
  }
  graph = new Graph({
    container: refContainer.value,
    ...InitGraphInfo,
    width: parseInt(graphInfo.value.graphW),
    height: parseInt(graphInfo.value.graphH),
    background: { color: graphInfo.value.bgColor },
    // minimap: {
    //   enabled: true,
    //   container: miniContainer.value,
    //   width: 200,
    //   height: 150
    // },
    connecting: {
      ...InitConnecting,
      createEdge() {
        return new Shape.Edge({
          attrs: {
            line: {
              stroke: '#1890ff',
              strokeDasharray: edgeInfo.strokeDasharray,
              sourceMarker: edgeInfo.sourceMarker,
              targetMarker: edgeInfo.targetMarker
            }
          }
        })
      },
      validateConnection({ targetMagnet }) {
        return !!targetMagnet
      }
    }
  })
  graph.centerContent()

  //对齐线
  // graph.use(
  //   new Snapline({
  //     enabled: true
  //   })
  // )
  //复制粘贴
  graph.use(
    new Clipboard({
      enabled: true,
      useLocalStorage: true
    })
  )
  //撤销重做
  graph.use(
    new History({
      enabled: true
    })
  )
  //框选
  graph.use(
    new Selection({
      enabled: true,
      multiple: true,
      rubberband: true,
      movable: true,
      strict: true,
      showNodeSelectionBox: true,
      showEdgeSelectionBox: true,
      modifiers: 'ctrl',
      pointerEvents: 'none'
    })
  )
  //快捷键
  graph.use(
    new Keyboard({
      enabled: true
    })
  )
  SetKeyBoard(graph)
  graph.bindKey('ctrl+s', () => {
    SaveInfo()
    return false
  })
  //节点变形
  if (graphInfo.value.transformEnable) {
    //启用
    graph.use(
      new Transform({
        resizing: { enabled: true },
        rotating: { enabled: true }
      })
    )
  }

  //事件
  graph.on('scale', ({ sx, sy, ox, oy }) => {
    graphInfo.value.scaleRate = graph.zoom()
  })
  graph.on('blank:click', () => {
    activeInfo.activeType = 'graphSet'
    selectNode.value = {}
    if (edgeInfo.showRoutePoint && JSON.stringify(selectEdge.value) != '{}') {
      selectEdge.value.removeTools()
      edgeInfo.showRoutePoint = false
    }
    selectEdge.value = {}
  })

  graph.on('node:mouseenter', ({ node }) => {
    if (graphInfo.value.usePorts) {
      showNodePorts(node, true)
      // node.setPortProp('top', 'attrs/circle/style', { visibility: 'visible' })

      // const ports = refContainer.value.querySelectorAll('.x6-port-body')
      // showPorts(ports, true)
    }
  })
  graph.on('node:mouseleave', ({ node }) => {
    if (graphInfo.value.usePorts) {
      showNodePorts(node, false)
    }
  })
  graph.on('node:click', ({ node }) => {
    if (graphInfo.value.usePorts) {
      showNodePorts(node, false)
    }

    activeInfo.activeType = 'nodeSet'
    nodeInfo.width = node.size().width
    nodeInfo.height = node.size().height
    nodeInfo.x = node.position().x
    nodeInfo.y = node.position().y
    nodeInfo.angle = node.getAngle()
    nodeInfo.zIndex = node.getZIndex()

    nodeInfo.bgColor = node.attr('body/fill') ?? '#ffffff'
    nodeInfo.borderColor = node.attr('body/stroke') ?? '#333333'
    nodeInfo.borderWidth =
      typeof node.attr('body/strokeWidth') != 'undefined'
        ? parseInt(node.attr('body/strokeWidth'))
        : 2
    nodeInfo.fontFamily = node.attr('text/fontFamily') ?? '微软雅黑'
    nodeInfo.fontSize =
      typeof node.attr('text/fontSize') != 'undefined'
        ? parseInt(node.attr('text/fontSize'))
        : 14
    nodeInfo.fontWeight =
      typeof node.attr('text/fontWeight') != 'undefined'
        ? node.attr('text/fontWeight') == 'bold'
        : false
    nodeInfo.fontStyle =
      typeof node.attr('text/fontStyle') != 'undefined'
        ? node.attr('text/fontStyle') == 'italic'
        : false
    nodeInfo.textDecoration =
      typeof node.attr('text/textDecoration') != 'undefined'
        ? node.attr('text/textDecoration') == 'underline'
        : false
    nodeInfo.fontColor = node.attr('text/fill') ?? '#333333'
    nodeInfo.textAnchor = node.attr('text/textAnchor')
    nodeInfo.textVerticalAnchor = node.attr('text/textVerticalAnchor')

    selectNode.value = node
    if (edgeInfo.showRoutePoint && JSON.stringify(selectEdge.value) != '{}') {
      selectEdge.value.removeTools()
      edgeInfo.showRoutePoint = false
    }
    selectEdge.value = {}

    InitNoedEvent()
    if (typeof selectNode.value.data != 'undefined') {
      noedEvent.hasMousedown = node.data.hasMousedown ?? false
      noedEvent.hasMouseup = node.data.hasMouseup ?? false
      noedEvent.hasClick = node.data.hasClick ?? false
      noedEvent.hasDblClick = node.data.hasDblClick ?? false
      noedEvent.hasShowHide = node.data.hasShowHide ?? false
      noedEvent.hasRotate = node.data.hasRotate ?? false
      noedEvent.hasBind = node.data.hasBind ?? false
      noedEvent.hasChangeColor = node.data.hasChangeColor ?? false
      noedEvent.hasChangeWH = node.data.hasChangeWH ?? false
    }
  })
  graph.on('node:resized', ({ e, x, y, node, view }) => {
    nodeInfo.width = node.size().width
    nodeInfo.height = node.size().height
  })
  graph.on('node:moved', ({ e, x, y, node, view }) => {
    nodeInfo.x = node.position().x
    nodeInfo.y = node.position().y
  })
  graph.on('node:rotated', ({ e, x, y, node, view }) => {
    nodeInfo.angle = node.getAngle()
  })
  graph.on('node:selected', ({ node }) => {
    selectMoreNode.value = graph.getSelectedCells().length > 1
  })
  graph.on('node:unselected', ({ node }) => {
    selectMoreNode.value = graph.getSelectedCells().length > 1
  })
  // graph.on('node:dblclick', ({ node, e }) => {
  //   // console.log(node.prop())
  //   // if (node.hasTools('node-editor'))
  //   // node.removeTool('node-editor')
  //   // node.addTools({
  //   //   name: 'node-editor'
  //   // })
  // })
  graph.on('edge:click', ({ e, edge, view }) => {
    activeInfo.activeType = 'edgeSet'
    selectEdge.value = edge
    edgeInfo.targetMarker = edge.attr('line/targetMarker')
    edgeInfo.sourceMarker = edge.attr('line/sourceMarker')
    edgeInfo.strokeDasharray = edge.attr('line/strokeDasharray')
    edgeInfo.fillColor = edge.attr('line/stroke')
    edgeInfo.zIndex = edge.getZIndex()
    edgeInfo.width = edge.attr('line/strokeWidth')
    selectNode.value = {}

    let connector = edge.getConnector()
    if (typeof connector != 'undefined') {
      if (connector.name != 'normal') {
        edgeInfo.connectorParames.type = connector.args.type
        edgeInfo.connectorParames.size = connector.args.size
        edgeInfo.connectorParames.radius = connector.args.radius
      } else edgeInfo.connectorParames.type = 'normal'
    }
    InitEdgeEvent()
    if (typeof edge.data != 'undefined') {
      edgeEvent.hasShowHide = edge.data.hasShowHide ?? false
      edgeEvent.hasFlow = edge.data.hasFlow ?? false
      edgeEvent.hasChangeColor = edge.data.hasChangeColor ?? false
    }
  })
  //加载节点
  GetAllNodes()
    .then((res) => {
      if (res && res.Total > 0) {
        var groups = reactive([])
        res.Data.forEach((item) => {
          groups.push({
            name: item.Name,
            graphHeight:
              refStencil.value.offsetWidth *
              0.4 *
              (item.NodeCount / 2 + (item.NodeCount % 2)),
            collapsed: true
          })
        })
        //绑定组件
        stencil = new Stencil({
          title: '系统节点库',
          // collapsable: true,
          stencilGraphWidth: refStencil.value.offsetWidth,
          stencilGraphHeight: 0,
          target: graph,
          groups: groups,
          layoutOptions: {
            columnWidth: refStencil.value.offsetWidth * 0.4,
            rowHeight: refStencil.value.offsetWidth * 0.4,
            resizeToFit: true
          },
          search: (cell, keyword, groupName, stencil) => {
            if (keyword) {
              return groupName.includes(keyword)
            }
            return true
          },
          placeholder: '请输入分组名称',
          notFoundText: '未找到匹配分组'
        })
        refStencil.value.appendChild(stencil.container)
        res.Data.forEach((item) => {
          var nodes = reactive([])
          if (item.NodeCount > 0) {
            item.NodeInfos.forEach((node) => {
              if (node.Type == 1) {
                nodes.push(
                  graph.createNode({
                    shape: 'path',
                    width: refStencil.value.offsetWidth * 0.4,
                    height: refStencil.value.offsetWidth * 0.4,
                    path: node.Info,
                    attrs: {
                      body: {
                        fill: 'black',
                        stroke: 'none'
                      }
                    },
                    ports: { ...InitPortsInfo },
                    tools: ['node-editor']
                  })
                )
              } else if (node.Type == 2 || node.Type == 3) {
                //图片
                let imgPath =
                  node.Type == 2
                    ? node.Info
                    : window.global_config.BASE_URL + node.Info
                nodes.push(
                  graph.createNode({
                    shape: 'image',
                    width: 60,
                    height: 60,
                    attrs: {
                      image: {
                        'xlink:href': imgPath
                      }
                    },
                    ports: { ...InitPortsInfo },
                    tools: ['node-editor']
                  })
                )
              } else if (node.Type == 4) {
                var nodeinfo = JSON.parse(node.Info)
                nodeinfo.ports = { ...InitPortsInfo }
                nodeinfo.tools = ['node-editor']
                nodes.push(graph.createNode(nodeinfo))
              }
            })
          }
          stencil.load(nodes, item.Name)
        })
      }
    })
    .catch((err) => {
      console.log(err)
    })
}
// 控制连接桩显示/隐藏
const showPorts = (ports, show) => {
  for (let i = 0, len = ports.length; i < len; i = i + 1) {
    ports[i].style.visibility = show ? 'visible' : 'hidden'
  }
}
const showNodePorts = (node, show) => {
  let ports = node.getPorts()
  if (ports.length > 0) {
    ports.forEach((port) => {
      node.setPortProp(port.id, 'attrs/circle/style', {
        visibility: show ? 'visible' : 'hidden'
      })
    })
  }
}
//页面设置
const handleChangeGraphSize = (val) => {
  if (val != '999') {
    graphInfo.value.graphW = val.split('*')[0]
    graphInfo.value.graphH = val.split('*')[1]
    graph.resize(
      parseInt(graphInfo.value.graphW),
      parseInt(graphInfo.value.graphH)
    )
  }
}
const handleChangGraphWH = (val) => {
  graph.resize(
    parseInt(graphInfo.value.graphW),
    parseInt(graphInfo.value.graphH)
  )
}
const handleChangeImg = (file, fileList) => {
  getBase64(file.raw).then((res) => {
    graphInfo.value.bgImage = res
    handleDrawGraphBg('')
  })
}
const setBgImg = (url) => {
  graphInfo.value.bgImage = url
  handleDrawGraphBg('')
}
const handleChangeGraphUseBgImage = (val) => {
  handleDrawGraphBg('')
}
const handleDrawGraphBg = (val) => {
  graph.drawBackground({
    color: graphInfo.value.bgColor,
    image: graphInfo.value.useBgImage ? graphInfo.value.bgImage : undefined,
    position: graphInfo.value.bgPosition,
    size: graphInfo.value.bgSize,
    repeat: graphInfo.value.bgRepeat,
    opacity: graphInfo.value.bgOpacity
  })
}
const handleChangeShowGraphGrid = (val) => {
  if (val) graph.showGrid()
  else graph.hideGrid()
}
const handleDrawGraphGrid = (val) => {
  graph.drawGrid({
    type: graphInfo.value.gridType,
    args: {
      color: graphInfo.value.gridColor, // 网格线/点颜色
      thickness: parseFloat(graphInfo.value.gridThickness) // 网格线宽度
    }
  })
}
const handleTransformEnable = (val) => {
  let cellInfo = graph.toJSON()
  initGraph()
  graph.fromJSON(cellInfo)
  initGraphInfo()
}
const handleSetGraphScale = (val) => {
  graph.zoomTo(val)
}
const handleChangeGridSize = (val) => {
  graph.setGridSize(val)
}
const initGraphInfo = () => {
  handleChangGraphWH(0)
  handleChangeGraphUseBgImage(graphInfo.value.useBgImage)
  handleChangeShowGraphGrid(graphInfo.value.showGrid)
  handleDrawGraphGrid('')
  handleChangeGridSize(parseFloat(graphInfo.value.gridSize))
  handleSetGraphScale(parseFloat(graphInfo.value.scaleRate ?? 1))
}

const setTipRules = (rules) => {
  graphInfo.value.tipRules = JSON.parse(JSON.stringify(rules))
}
const setGraphDialogRules = (rules) => {
  console.log(rules)
  graphInfo.value.dialogRules = JSON.parse(JSON.stringify(rules))
}
//节点设置
const handleChangeNodeWidth = (val) => {
  handleChangNodeBaseInfo(val, 'width')
}
const handleChangeNodeHeight = (val) => {
  handleChangNodeBaseInfo(val, 'height')
}
const handleChangeNodeX = (val) => {
  handleChangNodeBaseInfo(val, 'x')
}
const handleChangeNodeY = (val) => {
  handleChangNodeBaseInfo(val, 'y')
}
const handleChangeNodeAngle = (val) => {
  handleChangNodeBaseInfo(val, 'angle')
}

const handleChangNodeBaseInfo = (val, type) => {
  let cells = graph.getSelectedCells()
  if (cells.length > 0) {
    cells.forEach((cell) => {
      if (cell.isNode()) {
        switch (type) {
          case 'width':
            cell.size(parseFloat(val), parseFloat(cell.size().height))
            break
          case 'height':
            cell.size(parseFloat(cell.size().width), parseFloat(val))
            break
          case 'x':
            cell.position(parseFloat(val), parseFloat(cell.position().y))
            break
          case 'y':
            cell.position(parseFloat(cell.position().x), parseFloat(val))
            break
          case 'angle':
            cell.rotate(parseFloat(val), { absolute: true })
            break
          default:
            break
        }
      }
    })
  }
}
const handleChangeNodeBgColor = (val) => {
  ChangeNodeAttr('body/fill', val)
}
const handleChangeNodeBorderColor = (val) => {
  ChangeNodeAttr('body/stroke', val)
}
const handleChangeNodeBorderWidth = (val) => {
  ChangeNodeAttr('body/strokeWidth', val)
}
const handleChangeNodeFontSize = (val) => {
  ChangeNodeAttr('text/fontSize', val + 'px')
}
const handleChangeNodeFontColor = (val) => {
  ChangeNodeAttr('text/fill', val)
}
const handleChangeNodeFontWeight = (val) => {
  ChangeNodeAttr('text/fontWeight', val ? 'bold' : 'normal')
}
const handleChangeNodeFontStyle = (val) => {
  ChangeNodeAttr('text/fontStyle', val ? 'italic' : 'normal')
}
const handleChangeNodeTextDecoration = (val) => {
  ChangeNodeAttr('text/textDecoration', val ? 'underline' : 'none')
}
const handleChangeNodeTextVerticalAnchor = (val) => {
  ChangeNodeAttr('text/textVerticalAnchor', val)
}
const handleChangeNodeTextAnchor = (val) => {
  ChangeNodeAttr('text/textAnchor', val)
}

const handleChangeNodeZIndex = (val) => {
  let cells = graph.getSelectedCells()
  if (cells.length > 0) {
    cells.forEach((cell) => {
      if (cell.isNode()) {
        cell.setZIndex(parseInt(val))
      }
    })
  }
}
const handleChangeNodeHasMousedown = (val) => {
  ChangeNodeData_SelectChange('hasMousedown', val)
}
const handleChangeNodeHasMouseup = (val) => {
  ChangeNodeData_SelectChange('hasMouseup', val)
}
const handleChangeNodeHasClick = (val) => {
  ChangeNodeData_SelectChange('hasClick', val)
}
const handleChangeNodeHasDblClick = (val) => {
  ChangeNodeData_SelectChange('hasDblClick', val)
}
const handleChangeNodeHasShowHide = (val) => {
  ChangeNodeData_SelectChange('hasShowHide', val)
}
const handleChangeNodeHasRotate = (val) => {
  ChangeNodeData_SelectChange('hasRotate', val)
}
const handleChangeNodeHasBind = (val) => {
  ChangeNodeData_SelectChange('hasBind', val)
}
const handleChangeNodeHasChangeColor = (val) => {
  ChangeNodeData_SelectChange('hasChangeColor', val)
}
const handleChangeNodeHasChangeWH = (val) => {
  ChangeNodeData_SelectChange('hasChangeWH', val)
}
const setNodeData = (modeType, content) => {
  if (JSON.stringify(selectNode.value) != '{}') {
    let data = selectNode.value.data
    switch (modeType) {
      case 0:
        data.MousedownRule = content
        break
      case 1:
        data.MouseupRule = content
        break
      case 2:
        data.ClickRule = content
        break
      case 3:
        data.DblClickRule = content
        break
      default:
        break
    }
    selectNode.value.setData(data, { overwrite: true, silent: true })
  }
}
const setResponseData = (modeType, content) => {
  switch (modeType) {
    case 0:
      if (JSON.stringify(selectNode.value) != '{}') {
        let data = selectNode.value.data
        data.ShowHideRule = content
        selectNode.value.setData(data, { overwrite: true, silent: true })
      }
      break
    case 1:
      if (JSON.stringify(selectNode.value) != '{}') {
        let data = selectNode.value.data
        data.RotateRule = content
        selectNode.value.setData(data, { overwrite: true, silent: true })
      }
      break
    case 2:
      if (JSON.stringify(selectEdge.value) != '{}') {
        let data = selectEdge.value.data
        data.ShowHideRule = content
        selectEdge.value.setData(data, { overwrite: true, silent: true })
      }
      break
    case 3:
      if (JSON.stringify(selectEdge.value) != '{}') {
        let data = selectEdge.value.data
        data.FlowRule = content
        selectEdge.value.setData(data, { overwrite: true, silent: true })
      }
      break
    default:
      break
  }
}
const setChangeWHData = (content) => {
  if (JSON.stringify(selectNode.value) != '{}') {
    let data = selectNode.value.data
    data.ChangeWHRule = content
    selectNode.value.setData(data, { overwrite: true, silent: true })
  }
}
const setBindInfo = (content) => {
  if (JSON.stringify(selectNode.value) != '{}') {
    let data = selectNode.value.data
    data.BindRule = content
    selectNode.value.setData(data, { overwrite: true, silent: true })
  }
}
const setChangeColorRules = (modeType, rules) => {
  if (modeType == 0 && JSON.stringify(selectNode.value) != '{}') {
    let data = selectNode.value.data
    data.ChangeColorRule = rules
    selectNode.value.setData(data, { overwrite: true, silent: true })
  } else if (modeType == 1 && JSON.stringify(selectEdge.value) != '{}') {
    let data = selectEdge.value.data
    data.ChangeColorRule = rules
    selectEdge.value.setData(data, { overwrite: true, silent: true })
  }
}
const handleChangeNodeFontFamily = (val) => {
  ChangeNodeAttr('tspan/style', `font-family:'${val}'`)
}
const ChangeNodeAttr = (name, val) => {
  let cells = graph.getSelectedCells()
  if (cells.length > 0) {
    cells.forEach((cell) => {
      if (cell.isNode()) {
        cell.attr(name, val)
      }
    })
  }
}
const ChangeNodeData_SelectChange = (name, val) => {
  if (JSON.stringify(selectNode.value) != '{}') {
    let data = selectNode.value.data
    if (typeof data == 'undefined') data = {}
    Reflect.set(data, name, val)
    selectNode.value.setData(data, { overwrite: true, silent: true })
  }
}
//边设置
const ChangeSourceMarkerType = (val) => {
  ChangeEdgeAttr('line/sourceMarker', val)
}
const ChangeTargetMarkerType = (val) => {
  ChangeEdgeAttr('line/targetMarker', val)
}
const handleEdgeStrokeDasharrayChange = (val) => {
  ChangeEdgeAttr('line/strokeDasharray', val)
}

const handleEdgeWidthChange = (val) => {
  ChangeEdgeAttr('line/strokeWidth', val)
}
const handleChangeEdgeFillColor = (val) => {
  ChangeEdgeAttr('line/stroke', val)
}
const handleEdgeZIndexChange = (val) => {
  if (JSON.stringify(selectEdge.value) != '{}') {
    selectEdge.value.setZIndex(parseInt(val))
  }
}
const ChangeRouteVisible = (visible) => {
  if (visible) {
    edgeInfo.showRoutePoint = true
    let edges = graph.getEdges()
    if (edges.length > 0) {
      edges.forEach((edge) => {
        if (!edge.hasTools('vertices')) {
          graph.unselect(edge)
          edge.addTools(['vertices'])
        }
      })
    }
  } else {
    edgeInfo.showRoutePoint = false
    let edges = graph.getEdges()
    if (edges.length > 0) {
      edges.forEach((edge) => {
        edge.removeTools(['vertices'])
        // console.log(edge)
        // if (edge.hasTools('vertices')) {
        //   console.log(123)
        //   graph.select(edge)
        //   edge.removeTools(['vertices'])
        // }
      })
    }
  }
}
const handleChangeEdgeHasShowHide = (val) => {
  ChangeEdgeData_SelectChange('hasShowHide', val)
}
const handleChangeEdgeHasFlow = (val) => {
  ChangeEdgeData_SelectChange('hasFlow', val)
}
const handleChangeEdgeHasChangeColor = (val) => {
  ChangeEdgeData_SelectChange('hasChangeColor', val)
}
const ChangeEdgeData_SelectChange = (name, val) => {
  if (JSON.stringify(selectEdge.value) != '{}') {
    let data = selectEdge.value.data
    if (typeof data == 'undefined') data = {}
    Reflect.set(data, name, val)
    selectEdge.value.setData(data, { overwrite: true, silent: true })
  }
}
const ChangeEdgeAttr = (name, val) => {
  let cells = graph.getSelectedCells()
  if (cells.length > 0) {
    cells.forEach((cell) => {
      if (cell.isEdge()) {
        cell.attr(name, val)
      }
    })
  }
}
const ChangeEdgeConnector = (val) => {
  let cells = graph.getSelectedCells()
  if (cells.length > 0) {
    cells.forEach((cell) => {
      if (cell.isEdge()) {
        if (edgeInfo.connectorParames.type == 'normal')
          cell.setConnector('normal')
        else
          cell.setConnector('jumpover', {
            type: edgeInfo.connectorParames.type,
            size: edgeInfo.connectorParames.size,
            radius: edgeInfo.connectorParames.radius
          })
      }
    })
  }
}
onMounted(() => {
  if (typeof route.query.code != 'undefined') {
    let params = new FormData()
    params.append('code', route.query.code)
    GetScenesInfo(params)
      .then((res) => {
        document.title = '组态设计'
        scenceName.value = res.Data.Name
        scenesInfo.value = res.Data
        scenesBaseInfo.value = {
          Type: res.Data.Type,
          DeviceType: res.Data.DeviceType,
          DeviceTypeName: res.Data.DeviceTypeName
        }
        if (!isNull(scenesInfo.value.SceneInfo)) {
          try {
            graphInfo.value = JSON.parse(scenesInfo.value.SceneGraph)
            initGraph()
            graph.fromJSON(
              JSON.parse(
                scenesInfo.value.SceneInfo.replace(
                  reg,
                  window.global_config.BASE_URL
                )
              )
            )
            initGraphInfo()
          } catch (e) {
            console.log(e)
          }
        } else initGraph()
      })
      .catch((err) => {
        initGraph()
        console.log('获取场景信息异常:' + err)
      })
  } else initGraph()
})
onUnmounted(() => {
  if (JSON.stringify(graph) != '{}') {
    graph.dispose()
  }
})
const SaveInfo = () => {
  scenesInfo.value.SceneInfo = JSON.stringify(graph.toJSON()).replace(
    regUrl,
    '@@@'
  )
  scenesInfo.value.SceneGraph = JSON.stringify(graphInfo.value)
  if (EditScenesInfo(scenesInfo.value)) {
    ElMessage({
      message: '保存成功',
      type: 'success'
    })
  }
}
const PreView = () => {
  if (scenesBaseInfo.value.Type == 1) {
    deviceListDialogInfo.visible = true
  } else {
    if (typeof route.query.code != 'undefined') {
      const { href } = router.resolve({
        name: 'GraphPreview',
        query: { code: route.query.code }
      })
      window.open(href, '_blank')
    } else {
      ElMessage({
        message: '预览失败，场景编码无效',
        type: 'error'
      })
    }
  }
}
const SetDeviceID = (deviceID) => {
  if (typeof route.query.code != 'undefined') {
    const { href } = router.resolve({
      name: 'GraphPreview',
      query: { code: route.query.code, deviceID: deviceID, type: 1 }
    })
    window.open(href, '_blank')
  } else {
    ElMessage({
      message: '预览失败，场景编码无效',
      type: 'error'
    })
  }
}
//弹窗控制
const showTipDialog = () => {
  if (typeof graphInfo.value.tipRules != 'undefined')
    tipDialogInfo.content = graphInfo.value.tipRules
  else tipDialogInfo.content = []
  tipDialogInfo.visible = true
}
const showGraphDialog = () => {
  if (typeof graphInfo.value.dialogRules != 'undefined')
    graphDialogInfo.content = graphInfo.value.dialogRules
  else graphDialogInfo.content = {}
  graphDialogInfo.visible = true
}
const showActionDialog = (modeType) => {
  if (JSON.stringify(selectNode.value) != '{}') {
    actionDialogInfo.modeType = modeType
    switch (modeType) {
      case 0:
        actionDialogInfo.content = selectNode.value.getData().MousedownRule
        actionDialogInfo.visible = true
        break
      case 1:
        actionDialogInfo.content = selectNode.value.getData().MouseupRule
        actionDialogInfo.visible = true
        break
      case 2:
        actionDialogInfo.content = selectNode.value.getData().ClickRule
        actionDialogInfo.visible = true
        break
      case 3:
        actionDialogInfo.content = selectNode.value.getData().DblClickRule
        actionDialogInfo.visible = true
        break
      default:
        break
    }
  }
}
const showResponseDialog = (modeType) => {
  responseDialogInfo.modeType = modeType
  switch (modeType) {
    case 0:
      if (JSON.stringify(selectNode.value) != '{}') {
        responseDialogInfo.content = selectNode.value.getData().ShowHideRule
        responseDialogInfo.visible = true
      }
      break
    case 1:
      if (JSON.stringify(selectNode.value) != '{}') {
        responseDialogInfo.content = selectNode.value.getData().RotateRule
        responseDialogInfo.visible = true
      }
      break
    case 2:
      if (JSON.stringify(selectEdge.value) != '{}') {
        responseDialogInfo.content = selectEdge.value.getData().ShowHideRule
        responseDialogInfo.visible = true
      }
      break
    case 3:
      if (JSON.stringify(selectEdge.value) != '{}') {
        responseDialogInfo.content = selectEdge.value.getData().FlowRule
        responseDialogInfo.visible = true
      }
      break
    default:
      break
  }
}
const showBindDialog = () => {
  if (JSON.stringify(selectNode.value) != '{}') {
    bindDialogInfo.bindInfo = selectNode.value.getData().BindRule
    bindDialogInfo.visible = true
  }
}
const showChangeColorDialog = (modeType) => {
  if (modeType == 0 && JSON.stringify(selectNode.value) != '{}') {
    changeColorDialogInfo.rules = selectNode.value.getData().ChangeColorRule
    changeColorDialogInfo.visible = true
    changeColorDialogInfo.modeType = modeType
  } else if (modeType == 1 && JSON.stringify(selectEdge.value) != '{}') {
    changeColorDialogInfo.rules = selectEdge.value.getData().ChangeColorRule
    changeColorDialogInfo.visible = true
    changeColorDialogInfo.modeType = modeType
  }
}
const showChangeWHDialog = () => {
  if (JSON.stringify(selectNode.value) != '{}') {
    changeWHDialogInfo.content = selectNode.value.getData().ChangeWHRule
    changeWHDialogInfo.visible = true
  }
}
//初始化node事件绑定
const InitNoedEvent = () => {
  noedEvent.hasMousedown = false
  noedEvent.hasMouseup = false
  noedEvent.hasClick = false
  noedEvent.hasDblClick = false
  noedEvent.hasShowHide = false
  noedEvent.hasRotate = false
  noedEvent.hasBind = false
  noedEvent.hasChangeColor = false
  noedEvent.hasChangeWH = false
}
const InitEdgeEvent = () => {
  edgeEvent.hasShowHide = false
  edgeEvent.hasFlow = false
}

const clearRules = (index) => {
  ElMessageBox.confirm('是否确认删除当前规则?', '提醒', {
    confirmButtonText: '确认',
    cancelButtonText: '取消',
    type: 'warning'
  })
    .then(async () => {
      switch (index) {
        case 0: //画布--通知
          graphInfo.value.tipRules = []
          break
        case 1: //画布--弹窗
          graphInfo.value.dialogRules = {}
          break
        case 2: //组件--按下
          if (JSON.stringify(selectNode.value) != '{}') {
            selectNode.value.getData().MousedownRule = {}
          }
          break
        case 3: //组件--弹起
          if (JSON.stringify(selectNode.value) != '{}') {
            selectNode.value.getData().MouseupRule = {}
          }
          break
        case 4: //组件--单击
          if (JSON.stringify(selectNode.value) != '{}') {
            selectNode.value.getData().ClickRule = {}
          }
          break
        case 5: //组件--双击
          if (JSON.stringify(selectNode.value) != '{}') {
            selectNode.value.getData().DblClickRule = {}
          }
          break
        case 6: //组件--显藏
          if (JSON.stringify(selectNode.value) != '{}') {
            selectNode.value.getData().ShowHideRule = {}
          }
          break
        case 7: //组件--旋转
          if (JSON.stringify(selectNode.value) != '{}') {
            selectNode.value.getData().RotateRule = []
          }
          break
        case 8: //组件--绑定
          if (JSON.stringify(selectNode.value) != '{}') {
            selectNode.value.getData().BindRule = {}
          }
          break
        case 9: //组件--变色
          if (JSON.stringify(selectNode.value) != '{}') {
            selectNode.value.getData().ChangeColorRule = []
          }
          break
        case 10: //组件--变形
          if (JSON.stringify(selectNode.value) != '{}') {
            selectNode.value.getData().ChangeWHRule = []
          }
          break
        case 11: //边--显隐
          if (JSON.stringify(selectEdge.value) != '{}') {
            selectEdge.value.getData().ShowHideRule = []
          }
          break
        case 12: //边--流动
          if (JSON.stringify(selectEdge.value) != '{}') {
            selectEdge.value.getData().FlowRule = []
          }
          break
        case 13: //边--变色
          if (JSON.stringify(selectEdge.value) != '{}') {
            selectEdge.value.getData().ChangeColorRule = []
          }
          break
        default:
          break
      }
    })
    .catch(() => {})
}
//对齐
const handleAlignment = (type) => {
  if (selectMoreNode.value) {
    let cells = graph.getSelectedCells()

    // case 'width':
    //         cell.size(parseFloat(val), parseFloat(cell.size().height))
    //         break
    //       case 'height':
    //         cell.size(parseFloat(cell.size().width), parseFloat(val))
    //         break
    //       case 'x':
    //         cell.position(parseFloat(val), parseFloat(cell.position().y))
    //         break
    //       case 'y':
    //         cell.position(parseFloat(cell.position().x), parseFloat(val))
    //         break
    let minX = 0
    let maxX = 0
    let minY = 0
    let maxY = 0
    let xArr = []
    let yArr = []
    cells.forEach((cell) => {
      if (cell.isNode()) {
        xArr.push(cell.position().x)
        yArr.push(cell.position().y)
      }
    })
    graph.startBatch('rename')

    switch (type) {
      case 0: //左对齐
        minX = Math.min.apply(null, xArr)
        cells.forEach((cell) => {
          if (cell.isNode()) {
            cell.position(minX, parseFloat(cell.position().y))
          }
        })
        break
      case 1: //居中对齐
        minX = Math.min.apply(null, xArr)
        maxX = Math.max.apply(null, xArr)
        let centerX = (maxX + minX) / 2
        cells.forEach((cell) => {
          if (cell.isNode()) {
            cell.position(centerX, parseFloat(cell.position().y))
          }
        })
        break
      case 2: //右对齐
        maxX = Math.max.apply(null, xArr)
        cells.forEach((cell) => {
          if (cell.isNode()) {
            cell.position(maxX, parseFloat(cell.position().y))
          }
        })
        break
      case 3: //顶部对齐
        minY = Math.min.apply(null, yArr)
        console.log(minY)
        console.log(yArr)
        cells.forEach((cell) => {
          if (cell.isNode()) {
            cell.position(parseFloat(cell.position().x), minY)
          }
        })
        break
      case 4: //中部对齐
        minY = Math.min.apply(null, yArr)
        maxY = Math.max.apply(null, yArr)
        let centerY = (minY + maxY) / 2
        cells.forEach((cell) => {
          if (cell.isNode()) {
            cell.position(parseFloat(cell.position().x), centerY)
          }
        })
        break
      case 5: //底部对齐
        maxY = Math.max.apply(null, yArr)
        cells.forEach((cell) => {
          if (cell.isNode()) {
            cell.position(parseFloat(cell.position().x), maxY)
          }
        })
        break
      default:
        break
    }
    graph.stopBatch('rename')
  }
}
</script>
<style lang="scss" scoped src="@/styles/elementReset.scss"></style>
<style lang="scss" scoped src="./assets/index.scss"></style>

export const EdgeMarkerOptions = [
    {
        value: '',
        label: '无'
    },
    {
        value: 'block',
        label: '实心箭头'
    },
    {
        value: 'classic',
        label: '经典箭头'
    },
    {
        value: 'diamond',
        label: '菱形箭头'
    },
    {
        value: 'cross',
        label: '交叉箭头'
    },
    {
        value: 'circle',
        label: '圆形箭头'
    },
    {
        value: 'ellipse',
        label: '椭圆箭头'
    }
]
export const EdgeConnectorOptions = [
    {
        value: 'normal',
        label: '简单连接器'
    },
    {
        value: 'jumpover',
        label: '跳线连接器'
    },
    {
        value: 'rounded',
        label: '圆角连接器'
    }
]
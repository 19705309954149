// 节点初始化配置
export const FontFamilyOptions = [
    {
        value: '微软雅黑',
        label: '微软雅黑'
    },
    {
        value: '宋体',
        label: '宋体'
    },
    {
        value: '新宋体',
        label: '新宋体'
    },
    {
        value: '隶书',
        label: '隶书'
    },
    {
        value: '幼圆',
        label: '幼圆'
    },
    {
        value: '黑体',
        label: '黑体'
    },
    {
        value: '仿宋',
        label: '仿宋'
    },
    {
        value: '楷体',
        label: '楷体'
    },
    {
        value: 'Helvetica',
        label: 'Helvetica'
    },
    {
        value: 'Verdana',
        label: 'Verdana'
    },
    {
        value: 'Times New Roman',
        label: 'Times New Roman'
    },
    {
        value: 'Garamond',
        label: 'Garamond'
    },
    {
        value: 'Comic Sans MS',
        label: 'Comic Sans MS'
    },
    {
        value: 'Courier New',
        label: 'Courier New'
    },
    {
        value: 'Georgia',
        label: 'Georgia'
    },
    {
        value: 'Lucida Console',
        label: 'Lucida Console'
    },
    {
        value: 'Tahoma',
        label: 'Tahoma'
    }
]

//快捷键
export const SetKeyBoard = (graph) => {
    //复制
    graph.bindKey('ctrl+c', () => {
        const cells = graph.getSelectedCells()
        if (cells.length) {
            graph.copy(cells)
        }
        return false
    })
    //剪切
    graph.bindKey('ctrl+x', () => {
        const cells = graph.getSelectedCells()
        if (cells.length) {
            graph.cut(cells)
        }
        return false
    })
    //删除
    graph.bindKey(['ctrl+d', 'delete'], () => {
        const cells = graph.getSelectedCells()
        if (cells.length) {
            graph.removeCells(cells)
        }
        return false
    })
    //粘贴
    graph.bindKey('ctrl+v', () => {
        if (!graph.isClipboardEmpty()) {
            const cells = graph.paste({ offset: { dx: 0, dy: 30 } })
            // graph.cleanSelection()
            graph.resetSelection(cells)
        }
        return false
    })
    //撤销
    graph.bindKey('ctrl+z', () => {
        graph.undo()
        return false
    })
    //重做
    graph.bindKey('ctrl+y', () => {
        graph.redo()
        return false
    })
    //上移
    graph.bindKey('ctrl+up', () => {
        graph.startBatch('rename')
        let cells = graph.getSelectedCells()
        if (cells.length > 0) {
            for (let i = 0; i < cells.length; i++) {
                let position = cells[i].position()
                if (i == cells.length - 1) cells[i].position(position.x, position.y - 1)
                else cells[i].position(position.x, position.y - 1)
            }
        }
        graph.stopBatch('rename')
        return false
    })
    //下移
    graph.bindKey('ctrl+down', () => {
        let cells = graph.getSelectedCells()
        if (cells.length > 0) {
            cells.forEach((cell) => {
                let position = cell.position()
                cell.position(position.x, position.y + 1)
            })
        }
        return false
    })
    //左移
    graph.bindKey('ctrl+left', () => {
        let cells = graph.getSelectedCells()
        if (cells.length > 0) {
            cells.forEach((cell) => {
                let position = cell.position()
                cell.position(position.x - 1, position.y)
            })
        }
        return false
    })
    //右移
    graph.bindKey('ctrl+right', () => {
        let cells = graph.getSelectedCells()
        if (cells.length > 0) {
            cells.forEach((cell) => {
                let position = cell.position()
                cell.position(position.x + 1, position.y)
            })
        }
        return false
    })
    graph.bindKey('ctrl+shift+up', () => {
        graph.startBatch('rename')

        let cells = graph.getSelectedCells()
        if (cells.length > 0) {
            for (let i = 0; i < cells.length; i++) {
                let position = cells[i].position()
                cells[i].position(position.x, position.y - 10)
            }
        }

        graph.stopBatch('rename')

        return false
    })
    graph.bindKey('ctrl+shift+down', () => {
        let cells = graph.getSelectedCells()
        if (cells.length > 0) {
            cells.forEach((cell) => {
                let position = cell.position()
                cell.position(position.x, position.y + 10)
            })
        }
        return false
    })
    graph.bindKey('ctrl+shift+left', () => {
        let cells = graph.getSelectedCells()
        if (cells.length > 0) {
            cells.forEach((cell) => {
                let position = cell.position()
                cell.position(position.x - 10, position.y)
            })
        }
        return false
    })
    graph.bindKey('ctrl+shift+right', () => {
        let cells = graph.getSelectedCells()
        if (cells.length > 0) {
            cells.forEach((cell) => {
                let position = cell.position()
                cell.position(position.x + 10, position.y)
            })
        }
        return false
    })
}
<template>
  <el-dialog
    :model-value="dialogVisible"
    title="提示信息规则配置"
    width="500px"
    @opened="handleOpen"
    @close="handleClose"
    :close-on-click-modal="false"
    draggable
  >
    <el-form :model="form" label-width="70px" ref="formRef" :rules="rules">
      <el-form-item label="设备类型" v-if="scenesBaseInfo.Type == 1">
        <el-input v-model="scenesBaseInfo.DeviceTypeName" disabled />
      </el-form-item>
      <el-form-item
        label="设备"
        prop="deviceID"
        v-if="scenesBaseInfo.Type != 1"
      >
        <el-select
          v-model="selectDevice"
          placeholder="请选择设备"
          style="width: 100%"
          @change="handleChangeDevice"
          value-key="id"
          filterable
        >
          <el-option
            v-for="item in DeviceList"
            :key="item.id"
            :label="item.name"
            :value="item"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="参数" prop="selectParame">
        <el-select
          v-model="selectParame"
          placeholder="请选择参数"
          style="width: 100%"
          filterable
          value-key="name"
        >
          <el-option
            v-for="item in ParameList"
            :key="item.name"
            :label="item.name"
            :value="item"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="<=" prop="maxValue">
        <el-input v-model="form.maxValue" />
      </el-form-item>
      <el-form-item label=">=" prop="minValue">
        <el-input v-model="form.minValue" />
      </el-form-item>
      <el-form-item label="内容" prop="tipInfo">
        <el-input v-model="form.tipInfo" type="textarea" />
        <div style="color: red">当前值以###代替</div>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">取消</el-button>
        <el-button type="primary" @click="handleConfirm">确认</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { ref, reactive } from 'vue'
import { GetDeviceFillList } from '@/api/Device'
import {
  GetDeviceParameListByDeviceID,
  GetDeviceParameList
} from '@/api/DeviceParame'
import { ElMessage } from 'element-plus'
const props = defineProps({
  dialogRowValue: {
    type: Object,
    default: () => {}
  },
  ruleIndex: {
    type: Number,
    default: -1
  },
  scenesBaseInfo: {
    type: Object,
    default: {
      sceneType: 0,
      deviceTypeID: '',
      deviceTypeName: ''
    }
  }
})
var DeviceList = reactive([])
var ParameList = reactive([])
var selectDevice = ref({
  id: '',
  name: ''
})
var selectParame = ref({
  id: '',
  name: ''
})
const formRef = ref(null)
const form = ref({})
const scenesBaseInfo = ref({})
const dialogVisible = ref(false)
const rules = reactive({
  tipInfo: [{ required: true, message: '内容不能为空', trigger: 'blur' }],
  maxValue: [{ required: true, message: '值不能为空', trigger: 'blur' }],
  minValue: [{ required: true, message: '值不能为空', trigger: 'blur' }]
})

const emits = defineEmits(['update:modelValue', 'changeRules'])
const handleOpen = () => {
  scenesBaseInfo.value = props.scenesBaseInfo
  InitList()
  if (props.ruleIndex != -1) {
    form.value = props.dialogRowValue
    selectDevice.value = {
      id: form.value.deviceID,
      name: form.value.deviceName
    }
    selectParame.value = {
      id: form.value.parameID,
      name: form.value.parameName
    }
    GetParameList()
  } else {
    form.value = {}
    if (scenesBaseInfo.value.Type == 1) GetParameList()
  }
}
const handleClose = () => {
  emits('update:modelValue', false)
}
const handleConfirm = () => {
  if (
    scenesBaseInfo.value.Type != 1 &&
    (selectDevice.value.id == '' || selectParame.value.id == '')
  ) {
    ElMessage({
      message: '设备或参数不能为空',
      type: 'error'
    })
    return
  } else if (scenesBaseInfo.value.Type == 1 && selectParame.value.id == '') {
    ElMessage({
      message: '设备参数不能为空',
      type: 'error'
    })
    return
  }
  formRef.value.validate(async (valid, fields) => {
    if (valid) {
      form.value.deviceID = selectDevice.value.id
      form.value.deviceName = selectDevice.value.name
      form.value.parameID = selectParame.value.id
      form.value.parameName = selectParame.value.name
      emits('changeRules', props.ruleIndex, form.value)
      ElMessage({
        message: '操作成功',
        type: 'success'
      })
      handleClose()
    } else {
      console.log('error submit!', fields)
    }
  })
}

const InitList = () => {
  if (scenesBaseInfo.value.Type != 1) {
    let parame = { key: '' }
    GetDeviceFillList(parame)
      .then((res) => {
        DeviceList.length = 0
        res.Data.forEach((item) => {
          DeviceList.push({
            id: item.ID,
            name: item.Name
          })
        })
      })
      .catch((err) => {
        console.log(err)
      })
  }
}
const GetParameList = () => {
  ParameList.length = 0
  if (scenesBaseInfo.value.Type == 1) {
    let parame = {
      deviceTypeID: scenesBaseInfo.value.DeviceType,
      readType: ''
    }
    GetDeviceParameList(parame)
      .then((res) => {
        res.Data.forEach((item) => {
          ParameList.push({
            id: item.ID,
            name: item.Name
          })
        })
      })
      .catch((err) => {
        console.log(err)
      })
  } else {
    let parame = { deviceID: selectDevice.value.id, readType: '' }
    GetDeviceParameListByDeviceID(parame)
      .then((res) => {
        res.Data.forEach((item) => {
          ParameList.push({
            id: item.ID,
            name: item.Name
          })
        })
      })
      .catch((err) => {
        console.log(err)
      })
  }
}
const handleChangeDevice = (val) => {
  form.value.parameID = ''
  form.value.parameName = ''
  selectParame.value = {
    id: '',
    name: ''
  }
  GetParameList()
}
</script>

<style></style>
